(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/background-casinopromo/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/background-casinopromo/assets/javascripts/render-component.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
let casinoComponent;
let clientView; 
let LiveDataView;
let features;
let getLogger;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  getLogger = trinidad.logger;
  casinoComponent = trinidad.components.require('casino', 'game');
} else {
  getLogger = svs.core.log.getLogger;
  casinoComponent = svs.components.casino.game;
  LiveDataView = svs.banner_actions.casino_promo_actions.LiveDataView;
}


async function renderComponent(model, widgetInfo, position, req, res) {
  var _model$contentType;
  let hbsCompiled;
  let format;
  let isAdmin;
  let game;
  const logger = getLogger('banner_layouts:casino_promo');
  const {
    id: bannerId,
    images,
    layoutConfig,
    layoutType,
    title,
    targetOffer
  } = model;
  const hasImages = (images === null || images === void 0 ? void 0 : images.length) || null;
  if (isServer) {
    res.addUrlMappings(['casinoHome']);
    const trinidad = require('trinidad-core').core;
    const hbs = global.internalInstances.get('hbs');
    hbsCompiled = hbs.cache['components-banner_layouts-background_casinopromo-casinopromo'];
    format = trinidad.components.require('utils', 'format').api;
    isAdmin = req.userSession.hasRole(req.userSession.roles.INTERNAL);
    features = res.locals.features;
  } else {
    var _svs;
    hbsCompiled = svs.banner_layouts.background_casinopromo.templates.casinopromo;
    format = svs.utils.format;
    isAdmin = svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
    features = (_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.features;
  }
  const defaultThemeClass = 'dark-theme';
  const defaultLayoutOrder = 'default-order';
  const linkTitle = model.callToActions[0].actionConfig.linkTitle || 'Spela';
  const themeClass = layoutConfig.theme || defaultThemeClass;
  const layoutOrder = layoutConfig.layoutOrder || defaultLayoutOrder;
  const contentType = ((_model$contentType = model.contentType) === null || _model$contentType === void 0 ? void 0 : _model$contentType.split(':').pop()) || 'banner';
  const crm = targetOffer || '';
  const isFullWidth = true;
  const trackingStringTitle = format.safeString(title) || title;
  if (model.callToActions[0].actionConfig.casinoGameId) {
    try {
      if (isServer) {
        game = await casinoComponent.getGameByCmsId(model.callToActions[0].actionConfig.casinoGameId, req);
      } else {
        game = await casinoComponent.getGameByCmsIdClient(model.callToActions[0].actionConfig.casinoGameId);
      }
    } catch (e) {
      logger.warn("Error in fetching game: ".concat(e.toString()), req);
      game = null;
    }
  }
  if (game) {
    if (isServer) {
      res.addModels([{
        category: 'casino_promo',
        name: "game-".concat(game.name),
        model: _objectSpread(_objectSpread({}, game), {}, {
          playBtnTitle: linkTitle,
          bannerId
        })
      }]);
    } else {
      const el = '.background-casinopromo';
      const gameModel = new casinoComponent.GameModel(game);
      clientView = new LiveDataView(_objectSpread(_objectSpread({}, game), {}, {
        playBtnTitle: linkTitle,
        bannerId
      }), gameModel, el, true);
    }
  }
  const trackingString = "".concat(widgetInfo.widgetType, "/").concat(widgetInfo.trackingId, "/").concat(position, "/").concat(layoutType, "/").concat(trackingStringTitle);
  return hbsCompiled(_objectSpread(_objectSpread({}, layoutConfig), {}, {
    game,
    bannerId,
    contentType,
    crm,
    hasImages,
    landscapeImage: images === null || images === void 0 ? void 0 : images[0],
    portraitImage: images === null || images === void 0 ? void 0 : images[1],
    isAdmin,
    isFullWidth,
    layoutOrder,
    themeClass,
    linkTitle,
    trackingString,
    features
  })) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  setGlobal('svs.banner_layouts.background_casinopromo', {
    renderComponent
  });
}

 })(window);