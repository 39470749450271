(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/casino-info-tag/assets/javascripts/helpers/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/casino/casino-info-tag/assets/javascripts/helpers/handlebars-helpers.js');

'use strict';

let trinidad;
(function () {
  if (svs.isServer) {
    var _trinidad$components$;
    const hbs = require('hbs');
    trinidad = require('trinidad-core').core;
    const formatCurrency = (_trinidad$components$ = trinidad.components.require('utils', 'format').api) === null || _trinidad$components$ === void 0 ? void 0 : _trinidad$components$.Currency;
    registerHelpers(hbs, formatCurrency);
  } else {
    const formatCurrency = svs.utils.format.Currency;
    registerHelpers(Handlebars, formatCurrency);
  }
  function registerHelpers(hbs, formatCurrency) {
    hbs.registerHelper('getMoneyRange', (min, max) => {
      if (!min || !max) {
        return '';
      }
      return "".concat(formatCurrency(min), " kr - ").concat(formatCurrency(max), " kr");
    });
    hbs.registerHelper('getLiveStartTimeRage', (min, max) => {
      if (!min || !max) {
        return '';
      }
      return "Live ".concat(min, " - ").concat(max);
    });
  }
})();

 })(window);