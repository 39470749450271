(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/shared/assets/javascripts/shared.js') >= 0) return;  svs.modules.push('/components/casino/shared/assets/javascripts/shared.js');

'use strict';

var _ref, _ref2, _ref3, _ref4, _ref5, _ref6, _document$querySelect, _document$getElementB;
const log = new svs.core.log.Logger('svs.components.casino.shared');
let overrideTimeLimitDialogMethod = null;
let gameInfoView;
let quickInfoView;
const infoContainer = document.getElementById('casino-game-info-container');
const bodyElement = document.body;
const quickInfoContainer = document.getElementById('casino-quick-info-container');
const lobbyContentContainer = (_ref = (_ref2 = (_ref3 = (_ref4 = (_ref5 = (_ref6 = (_document$querySelect = document.querySelector('#lobby-page')) !== null && _document$querySelect !== void 0 ? _document$querySelect : document.querySelector('.pnp-category-page-content')) !== null && _ref6 !== void 0 ? _ref6 : document.querySelector('#my-games-page')) !== null && _ref5 !== void 0 ? _ref5 : document.querySelector('#live-lobby-page')) !== null && _ref4 !== void 0 ? _ref4 : document.querySelector('#lb-casino-search-page')) !== null && _ref3 !== void 0 ? _ref3 : document.querySelector('#casino-search')) !== null && _ref2 !== void 0 ? _ref2 : document.querySelector('#category-page')) !== null && _ref !== void 0 ? _ref : document.querySelector('#no-game-page-error-page');
const pnpQuickTransactionElement = document.querySelector('.js-quick-transaction-buttons');
const LOCALSTORAGE_KEY = 'casino_activeSearchResult';
const searchPageWidget = (_document$getElementB = document.getElementById('findContent')) !== null && _document$getElementB !== void 0 ? _document$getElementB : document.querySelector('.casino-categories');
const topMenyEl = document.querySelector('.js-casino-top-menu');
const urlMapping = svs.core.urlMapping;
const casinoShared = {
  startGame(playType, gameName, analyticsData) {
    let gameModel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    let addToLastSearched = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    const redirectToGame = () => {
      var _svs$components$casin;
      const game = gameModel || svs.components.casino.games[gameName] || ((_svs$components$casin = svs.components.casino) === null || _svs$components$casin === void 0 || (_svs$components$casin = _svs$components$casin.data) === null || _svs$components$casin === void 0 || (_svs$components$casin = _svs$components$casin.gameCache) === null || _svs$components$casin === void 0 ? void 0 : _svs$components$casin.index[gameName]);
      const baseUrl = svs.core.config.data.svsconfig.site === 'momang' ? svs.core.data.baseUrl : urlMapping.get('casinoHome'); 
      const lobbyType = game.lobbyType === 'live' ? 'live' : 'slots';
      const queryParams = "?lobbyType=".concat(encodeURIComponent(lobbyType), "&provider=").concat(encodeURIComponent(game.provider), "&addToLastSearched=").concat(addToLastSearched, "&returnUrl=").concat(encodeURIComponent(location.href));
      window.location = "".concat(baseUrl, "/").concat(playType, "/").concat(gameName).concat(queryParams);
    };
    try {
      svs.components.casino.shared.checkPlayerPause(() => {
        svs.components.casino.shared.checkGameLimits() && svs.components.casino.shared.trackPromoClick(analyticsData, redirectToGame);
      });
    } catch (_unused) {
      redirectToGame();
    }
  },
  trackEvent(event, action, label, value, callback) {
    const eventData = {
      category: 'Casino',
      event,
      action,
      opt_label: label,
      opt_value: value 
    };
    svs.components.analytics.trackEvent(eventData, callback);
  },
  trackPromoClick(options, callback) {
    const eventData = {
      opt_label: 'Casino',
      id: options.name,
      name: options.title,
      position: options.position 
    };
    svs.components.analytics.trackPromoClick(eventData, callback);
  },
  checkGameLimits() {
    if (svs.components.casino.data.gamingTimeLimits && svs.components.casino.data.gamingTimeLimits.hasReachedTimeLimit === true) {
      const dialogOptions = {
        branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
        title: 'Du har nått din satta tidsgräns',
        icon: 'time-limit',
        area: svs.ui.dialog.area.POPUP,
        type: svs.ui.dialog.type.CONFIRM,
        product: 'Casino',
        message: [{
          type: svs.ui.dialog.message.TEXT,
          text: 'När du nått din tidsgräns kan du inte betala för spel på Sport & Casino.'
        }],
        actions: [{
          title: 'Stäng',
          callback: () => {
            dialog.close();
          }
        }, {
          title: 'Till mina gränser',
          callback: () => {
            svs.utils.url.navigate(svs.core.urlMapping.get('myProfileLimits'));
          }
        }]
      };
      const dialog = new svs.ui.dialog.Confirm(dialogOptions);
      svs.ui.dialog.api.add(dialog);
      return false;
    }
    return true;
  },
  openTimeLimitDialogMiddleware(headline, text) {
    if (overrideTimeLimitDialogMethod) {
      overrideTimeLimitDialogMethod.call(this, headline, text);
    } else {
      this.openTimeLimitDialog(headline, text);
    }
  },
  openTimeLimitDialog(title, text) {
    const actions = [];
    if (svs.components.casino.data.gamingTimeLimits && svs.components.casino.data.gamingTimeLimits.hasReachedTimeLimit) {
      actions.push({
        title: 'Stäng',
        callback: () => {
          dialog.close();
        }
      });
      actions.push({
        title: 'Logga ut',
        callback: () => {
          window.location.href = "/logga-ut?returnUrl=".concat(encodeURIComponent(svs.core.urlMapping.get('casinoHome')));
        }
      });
    } else {
      actions.push({
        title: 'Okej',
        callback: () => {
          dialog.close();
        }
      });
    }
    const dialogOptions = {
      branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
      title,
      icon: 'time-limit',
      area: svs.components.casino.data.gamingTimeLimits && svs.components.casino.data.gamingTimeLimits.hasReachedTimeLimit ? svs.ui.dialog.area.POPUP : svs.ui.dialog.area.TOASTER,
      type: svs.ui.dialog.type.DEFAULT,
      product: 'Casino',
      message: [{
        type: svs.ui.dialog.message.TEXT,
        text
      }],
      actions
    };
    const dialog = new svs.ui.dialog.Confirm(dialogOptions);
    svs.ui.dialog.api.add(dialog);
  },
  overrideTimeLimitDialog(method) {
    overrideTimeLimitDialogMethod = method;
  },
  startGamingTimeLimitsTimer() {
    if (svs.components.casino.data.gamingTimeLimits && svs.components.casino.data.gamingTimeLimits.closestTimeLimit) {
      const startTime = new Date();
      const timeGroupText = {
        day: 'dagliga',
        week: 'veckovisa',
        month: 'månatliga'
      };
      const timeGroup = timeGroupText[svs.components.casino.data.gamingTimeLimits.closestTimeLimit.group];
      if (svs.components.casino.data.gamingTimeLimits.hasReachedTimeLimit) {
        const message = "N\xE4r du har n\xE5tt din tidsgr\xE4ns kan du inte betala f\xF6r spel p\xE5 Sport & Casino.\n        <br><br>Se dina inst\xE4llningar under <a class=\"u-text-decoration-none\" href=\"".concat(svs.core.urlMapping.get('myProfileLimits'), "\">Mina Gr\xE4nser</a>.");
        this.openTimeLimitDialogMiddleware('Du har nått din tidsgräns', message);
      } else {
        this.setRepeatingTimeLimitChecks(startTime, timeGroup);
      }
    }
  },
  setRepeatingTimeLimitChecks(startTime, timeGroup) {
    let frequency = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
    setTimeout(() => {
      const currentTime = new Date().getTime();
      const minutesPassed = (currentTime - startTime) / 60000;
      const minutesRemaining = Math.floor(svs.components.casino.data.gamingTimeLimits.closestTimeLimit.remaining - minutesPassed);
      if (minutesRemaining < 5) {
        const remainingTimeTexts = ['en minut', 'två minuter', 'tre minuter', 'fyra minuter', 'fem minuter'];
        const message = "Om ".concat(remainingTimeTexts[minutesRemaining], " uppn\xE5r du din ").concat(timeGroup, " tidsgr\xE4ns.\n          N\xE4r du uppn\xE5tt din tidsgr\xE4ns kan du inte betala spel p\xE5 Casino ").concat(this.isPnp() ? '' : 'eller Bingo');
        this.openTimeLimitDialogMiddleware('Tidsgräns snart uppnådd', message);
        return;
      }
      const frequency = minutesRemaining > 20 ? 5 * 60000 : 1000;
      this.setRepeatingTimeLimitChecks(startTime, timeGroup, frequency);
    }, frequency);
  },
  async checkPlayerPause(success, failure) {
    const canPlay = await svs.accountservices.player_exclusions.canPlayOnVertical('casino');
    if (canPlay) {
      success === null || success === void 0 || success();
    } else {
      failure === null || failure === void 0 || failure();
    }
  },
  initLogoAnimation() {
    svs.components.Storage.browser.get('logo-animation', 'casino', (data, err) => {
      if (err || !data) {
        svs.components.Storage.browser.set('logo-animation', 'casino', {
          use: 'false'
        }, svs.components.Storage.constants.EXPIRES_10M, () => {
        });
      } else {
        $('.header-casino-animate').removeClass('header-casino-animate');
      }
    });
  },
  getDeviceType() {
    if (svs.core.detect.formfactor.mobile()) {
      return 'mobile';
    } else if (svs.core.detect.os.iOS() || svs.core.detect.os.android()) {
      return 'tablet';
    }
    return 'desktop';
  },
  initSearchState() {
    svs.casino.activeSearchFilters = {
      count: 0,
      q: '',
      sort: {
        mode: 'default',
        label: 'Sortera',
        direction: 'asc'
      }
    };
  },
  restoreSearchState(callback) {
    if (this.hasSearchState()) {
      callback(svs.casino.activeSearchFilters);
      return;
    }
    const result = localStorage.getItem(LOCALSTORAGE_KEY);
    if (result) {
      const parsedResult = JSON.parse(result);
      svs.casino.activeSearchFilters = parsedResult;
      callback(parsedResult);
      return;
    }
    this.initSearchState();
    callback(svs.casino.activeSearchFilters);
  },
  recordSearchState() {
    if (!this.hasSearchState()) {
      this.initSearchState();
    }
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(svs.casino.activeSearchFilters));
  },
  hasSearchState() {
    return Boolean(svs.casino.activeSearchFilters);
  },
  updatePageTitle() {
    let title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "Spela ".concat(this.isPnp() && 'Momang ', "Casino Online - Stort utbud hos Svenska Spel");
    document.title = title;
  },
  renewSession() {
    try {
      svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER) && svs.core.jupiter.get('/player/1/balance');
    } catch (e) {
      log.warn('Error renewing player session', e);
    }
  },
  togglePnpQuickTransaction() {
    let hide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    if (this.isPnp() && pnpQuickTransactionElement) {
      if (hide) {
        pnpQuickTransactionElement.classList.add('hidden');
      } else {
        pnpQuickTransactionElement.classList.remove('hidden');
      }
    }
  },
  showGameInfo(gameName, shouldToggleNavigation) {
    if (typeof gameName !== 'string') {
      gameName = gameName[1]; 
      log.warn('gameName not a string:', gameName, window.location.href);
    }
    gameInfoView = new svs.components.casino.InfoPage(infoContainer, svs.components.casino.games[gameName], true);
    infoContainer.classList.add('show-info');
    if (this.isPnp()) {
      const headerElement = document.querySelector('.pnp-header');
      headerElement.classList.add('fixed-top-content');
    } else {
      bodyElement.classList.add('bg-casino-1000');
      bodyElement.classList.remove('bg-white');
    }
    lobbyContentContainer && lobbyContentContainer.classList.add('hide-lobby-content');
    topMenyEl && topMenyEl.classList.add('js-hide-top-nav');
    this.togglePnpQuickTransaction();
    searchPageWidget && searchPageWidget.classList.add('hide-lobby-content');
  },
  hideGameInfo() {
    if (svs.components.revealAnimation.isRevertable()) {
      svs.components.revealAnimation.prepareRevertRevealAnimation(() => {
        infoContainer.classList.remove('show-info');
        svs.components.revealAnimation.revertRevealAnimation();
        gameInfoView && gameInfoView.destroy();
      });
      lobbyContentContainer && lobbyContentContainer.classList.remove('hide-lobby-content');
      topMenyEl && topMenyEl.classList.remove('js-hide-top-nav');
      searchPageWidget && searchPageWidget.classList.remove('hide-lobby-content');
    } else {
      infoContainer && infoContainer.classList.contains('show-info') && svs.components.animation.add(infoContainer, 'animate animate-hide animate-time-2ms', () => {
        infoContainer.classList.remove('show-info');
        gameInfoView && gameInfoView.destroy();
      });
      lobbyContentContainer && lobbyContentContainer.classList.remove('hide-lobby-content');
      searchPageWidget && searchPageWidget.classList.remove('hide-lobby-content');
    }
    if (!this.isPnp()) {
      bodyElement.classList.add('bg-white');
      bodyElement.classList.remove('bg-casino-1000');
    }
    this.togglePnpQuickTransaction(false);
  },
  isPnp() {
    return svs.core.config.data.svsconfig.site === 'momang';
  },
  hideQuickInfo() {
    if (quickInfoView && quickInfoView.isOpen) {
      quickInfoView.tl.eventCallback('onReverseComplete', () => {
        quickInfoView.destroy();
      });
      quickInfoView.animateHide();
    }
  },
  showQuickInfo(gameName, e) {
    if (typeof gameName !== 'string') {
      gameName = gameName[1]; 
      log.warn('gameName not a string:', gameName, window.location.href);
    }
    if (quickInfoView && quickInfoView.game && quickInfoView.game.name === gameName) {
      return false;
    }
    const game = svs.components.casino.games[gameName] || gameName;
    if (quickInfoView && quickInfoView.isOpen) {
      quickInfoView.animateHide();
      quickInfoView.tl.eventCallback('onReverseComplete', () => {
        quickInfoView.destroy();
        quickInfoView = new svs.components.casino.QuickInfo(quickInfoContainer, game);
        quickInfoView.animateShow();
      });
    } else {
      quickInfoView = new svs.components.casino.QuickInfo(quickInfoContainer, game);
      quickInfoView.animateShow(e);
    }
  }
};
setGlobal('svs.components.casino.shared', casinoShared);

 })(window);