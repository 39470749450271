(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/casino-teaser/assets/javascripts/casino-teaser.js') >= 0) return;  svs.modules.push('/widgets/casino-teaser/assets/javascripts/casino-teaser.js');

'use strict';

class CasinoTeaser {
  constructor(options) {
    this.log = new svs.core.log.Logger('widgets:casino-teaser');
    this.model = options.model;
    this.el = options.$el;
    this.gamePromoViews = {};
    this.currentWindowWidth = null;
    this.breakWidth = 1024;
    if (this.model.inCasino) {
      this.placeholderTemplate = svs.casino.placeholder.templates.placeholder;
      this.placeholder = null;
      this.createObserver();
      this.useGameCache();
      this.initGamePromos();
      this.addListeners();
      this.handlePlaceholders();
    } else {
      this.addListeners();
      this.gamePromoViews = this.model.games;
      this.appendGamePromoEls();
    }
    this.resizeGames(false);
    this.toggleVisibility();
    this.el.closest('.widget').data('casino-widget', this);
  }
  useGameCache() {
    if (this.model.games) {
      svs.components.casino.game.extendCache(this.model.games, true);
      const games = [];
      for (const game of this.model.games) {
        games.push(svs.components.casino.games[game.name]);
      }
      this.model.games = games;
    }
  }
  addListeners() {
    if (this.isFavoriteWidget()) {
      svs.components.casino.favorites.on('change', this, this.onFavoriteUpdate);
    }
    this.el.find('.js-widget-casino-teaser-link').on('click', this.trackClick.bind(this));
    $(window).on('resize', () => this.resizeGames());
    if (!this.model.inCasino) {
      this.el.find('.js-game-promo-btn-play').on('click', e => {
        this.trackPromoClick(e);
      });
    }
  }
  async onFavoriteUpdate(updatedFavorite) {
    const favorites = await svs.components.casino.favorites.get();
    const favoriteIsAVisiblePromoView = this.gamePromoViews[updatedFavorite];
    const gameExists = svs.components.casino.games[updatedFavorite];
    !favoriteIsAVisiblePromoView && gameExists && this.insertGame(updatedFavorite);
    Object.keys(this.gamePromoViews).forEach(gamePromoView => {
      !favorites.includes(gamePromoView) && this.removeGame(gamePromoView);
    });
    this.handlePlaceholders();
    this.toggleVisibility();
  }
  handlePlaceholders() {
    if (this.isEmpty()) {
      this.createPlaceholder(this.isFavoriteWidget() ? 'favorites' : '', '', this.isFavoriteWidget() ? 'Här hittar du alla dina favoritspel.' : 'Här hittar du dina senaste spelade spel.');
      return;
    }
    this.removePlaceholder();
  }
  insertGame(gameName) {
    if (gameName) {
      const model = {
        showFavorite: this.isFavoriteWidget(),
        game: svs.components.casino.games[gameName],
        format: this.model.styling.format,
        showOrder: this.model.styling.showOrder
      };
      const el = $(svs.widget.casino_teaser.templates.game(model));
      const position = this.el.find('.js-casino-game-promo').length++;
      this.gamePromoViews[gameName] = new svs.components.casino.game.PromoView(svs.components.casino.games[gameName], {
        observer: this.observer,
        initPosition: position,
        showFavorite: this.model.inCasino,
        area: this.formattedPromoTitle(),
        format: this.model.styling.format,
        showOrder: this.model.styling.showOrder
      }, el.find('.js-casino-game-promo'));
      this.el.find('.js-widget-casino-teaser-games').prepend(el);
    }
  }
  async initGamePromos() {
    this.el.find('.js-casino-game-promo').each((i, el) => {
      const gameName = $(el).data('name');
      const gameModel = svs.components.casino.games[gameName];
      this.gamePromoViews[gameName] = new svs.components.casino.game.PromoView(gameModel, {
        observer: this.observer,
        initPosition: i,
        area: this.formattedPromoTitle(),
        format: this.model.styling.format,
        showOrder: this.model.styling.showOrder
      }, el);
    });
    if (this.isFavoriteWidget()) {
      this.onFavoriteUpdate();
    }
  }
  appendGamePromoEls() {
    this.el.find('.js-casino-game-promo').each((i, el) => {
      this.gamePromoViews[i].el = $(el);
    });
  }
  exceededViewWidthThreshold() {
    const aboveBoundaryCheck = window.innerWidth >= this.breakWidth && this.currentWindowWidth >= this.breakWidth;
    const belowBoundaryCheck = window.innerWidth < this.breakWidth && this.currentWindowWidth < this.breakWidth;
    return aboveBoundaryCheck || belowBoundaryCheck;
  }
  createPlaceholder(icon, title, information) {
    if (!this.placeholder) {
      this.placeholder = $(this.placeholderTemplate({
        icon,
        iconSize: '400',
        title,
        information
      }));
      this.el.find('.widget-casino-teaser-games').append(this.placeholder);
    }
  }
  formattedPromoTitle() {
    return this.model.styling.title.replace(' ', '-').toLowerCase();
  }
  createObserver() {
    if (window.IntersectionObserver) {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(e => {
          const gamePromoView = $(e.target).data('gamePromoView');
          if (e.isIntersecting === true || e.intersectionRatio > 0) {
            gamePromoView.visible();
          } else {
            gamePromoView.hidden();
          }
        });
      }, {
        rootMargin: '0px 0px 250px 0px'
      });
    }
  }

  trackClick() {
    const title = this.model.styling.title;
    if (title) {
      const eventData = {
        category: 'Casino',
        event: 'click',
        action: 'Categories',
        opt_label: this.formattedPromoTitle()
      };
      svs.components.analytics.trackEvent(eventData);
    }
  }
  trackPromoClick(e) {
    const node = e.target.closest('.js-casino-game-promo');
    if (node && node.dataset) {
      const eventData = {
        id: node.dataset.name,
        name: "".concat(node.dataset.provider, ";").concat(node.dataset.name),
        position: node.dataset.areaName.split(';')[0] || 'Casino',
        event: 'promoClick'
      };
      svs.components.analytics.trackPromoClick(eventData);
    }
  }
  resizeGames() {
    let checkThreshold = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    if (checkThreshold && this.exceededViewWidthThreshold()) {
      this.currentWindowWidth = window.innerWidth;
      return;
    }
    const promoKeys = Object.keys(this.gamePromoViews);
    if (promoKeys.length < 1) {
      return;
    }
    const isMobile = svs.core.detect.formfactor.mobile();
    const breakWidth = this.breakWidth;
    this.currentWindowWidth = window.innerWidth;
    const doubleRows = [6, 8];
    const singleRows = [3, 4];
    const target = this.currentWindowWidth < breakWidth ? 0 : 1;
    let rowType;
    if (this.model.styling.format === 'standard') {
      rowType = !isMobile ? doubleRows : singleRows;
    } else if (this.model.styling.format === 'doubleRows') {
      rowType = doubleRows;
    } else {
      rowType = singleRows;
    }
    promoKeys.forEach((key, i) => {
      if (!isMobile && i > rowType[target] - 1 && this.model.areaName !== 'myGamesContent') {
        this.gamePromoViews[key].el.addClass('hide');
        return;
      }
      this.gamePromoViews[key].el.removeClass('hide');
    });
  }
  removeGame(gameName) {
    const gamePromoView = this.gamePromoViews[gameName];
    const el = gamePromoView.el.closest('.widget-casino-teaser-game');
    gamePromoView.destroy();
    delete this.gamePromoViews[gameName];
    el.remove();
  }
  removeAllGames() {
    for (const gameName of Object.keys(this.gamePromoViews)) {
      this.removeGame(gameName);
    }
  }
  removeListeners() {
    if (this.isFavoriteWidget()) {
      svs.components.casino.favorites.off('change', this);
    }
    this.el.find('.js-widget-casino-teaser-link').off('click');
  }
  removePlaceholder() {
    if (this.placeholder) {
      this.placeholder.remove();
      this.placeholder = null;
    }
  }
  isEmpty() {
    return !Object.keys(this.gamePromoViews).length;
  }
  isFavoriteWidget() {
    return this.model.contentType === 'favorites';
  }
  isLoggedIn() {
    return svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  }
  toggleVisibility() {
    if (this.isFavoriteWidget()) {
      this.model.hideEmpty && this.el.toggleClass('hide', this.isEmpty() && !this.isLoggedIn());
    } else {
      this.model.hideEmpty && this.el.toggleClass('hide', this.isEmpty());
    }
  }
  destroy() {
    this.removeAllGames();
    this.removeListeners();
  }
}
setGlobal('svs.widget.casino_teaser.init', options => {
  if (options && options.model) {
    new CasinoTeaser(options);
  }
});

 })(window);