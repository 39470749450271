(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/popover/assets/javascripts/popover.js') >= 0) return;  svs.modules.push('/components/components/popover/assets/javascripts/popover.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};

  svs.components.Popover = function(targetobj, options) {
    return {
      init: function() {
        var opts = $.extend({}, svs.components.Popover.Defaults, options);
        var target = {};
        var css = {};
        var self = this;

        opts.onBeforeInit();

        if (opts.speechBubble) {
          opts.branding = 'popover-speech-bubble';
        }

        target.obj = targetobj.first();
        target.width = target.obj.outerWidth();
        target.height = target.obj.outerHeight();
        target.pos = target.obj.position();

        this.onOutsideClickProxy = $.proxy(this.onOutsideClick, this);

        this.target = target;
        this.options = opts;
        this.obj = options.template ?
          options.template :
          $(svs.components.popover.templates.popover(opts));
        this.closeIcon = this.obj.find('.js-popover-close');

        if (
          svs.utils.ns.isNamespace('React.isValidElement') &&
          typeof React.isValidElement === 'function' &&
          React.isValidElement(opts.content) &&
          svs.utils.ns.isNamespace('ReactDOM.render') &&
          typeof ReactDOM.render === 'function'
        ) {
          ReactDOM.render(
            opts.content,
            this.obj.find('.js-popover-content').get()[0]
          );
        } else if (typeof opts.content === 'object') {
          this.obj
            .find('.js-popover-content')
            .html('')
            .append(opts.content);
        }
        if (
          this.options.speechBubble &&
          this.options.speechBubbleAlignment === 'left'
        ) {
          $('body').append(this.obj);
        } else {
          target.obj.after(this.obj);
        }

        var calculateSpeechBubblePosition = function(css) {
          var targetLeft = target.obj.offset().left + target.width / 2;
          var targetTop = target.obj.offset().top;
          var containerWidth;
          var objWidth = self.obj.outerWidth();
          var objHeight = self.obj.outerHeight();
          var speechBubbleAlignment = 'bottom';

          if (opts.speechBubbleAlignment) {
            speechBubbleAlignment = opts.speechBubbleAlignment;
          }

          if (opts.container) {
            containerWidth = opts.container.width();
          } else {
            containerWidth = $(window).width();
          }

          self.obj
            .find('.arrow')
            .removeClass('arrow-right arrow-center arrow-top');

          var topLimit = $('.js-top-content').height();

          if (opts.container) {
            topLimit = opts.container.offset().top;
          }

          if (speechBubbleAlignment === 'bottom') {
            if (targetTop - 12 - topLimit > objHeight) {
              css.top = 'auto';
              css.bottom = target.obj.outerHeight() + 12;
            } else {
              css.bottom = 'auto';
              css.top = target.obj.outerHeight() + 24;

              self.obj.find('.arrow').addClass('arrow-top');
            }

            if (containerWidth - (targetLeft + objWidth) >= 0) {
              css.right = 'auto';
              css.left =
                target.pos.left +
                target.width / 2 +
                opts.offset.left -
                objWidth / 2;
              self.obj
                .find('.arrow')
                .css('left', objWidth / 2 + opts.arrowoffset);
            } else if (targetLeft - objWidth >= 0) {
              css.right =
                target.pos.left +
                target.width / 2 +
                opts.offset.left -
                objWidth / 2;
              css.left = 'auto';
              self.obj
                .find('.arrow')
                .css('right', 0)
                .css('left', 'auto')
                .addClass('arrow-right');
            } else {
              css.right = 'auto';
              css.left = target.obj.offset().left * -1 + 20;
              self.obj
                .find('.arrow')
                .css('left', targetLeft - 20)
                .addClass('arrow-center');
            }
          } else if (speechBubbleAlignment === 'left') {
            var right =
                $(window).width() -
                target.obj.offset().left +
                opts.offset.right;
            var top = targetTop + target.height / 2 + opts.offset.top;

            css.zIndex = '1000';
            css.bottom = 'auto';
            css.top = top;
            css.left = 'auto';
            css.right = right;
            self.obj.find('.arrow').addClass('arrow-top-right');
          }
        };

        if (opts.speechBubble) {
          calculateSpeechBubblePosition(css);
        } else {
          var arrowCorrection = 0;
          switch (opts.position) {
            case 'top':
              css.top = 'auto';
              css.bottom = target.obj.outerHeight() + 12 + opts.offset.bottom;
              css.left =
                target.pos.left +
                target.width / 2 +
                opts.offset.left -
                this.obj.outerWidth() / 2;
              this.obj
                .find('.arrow')
                .css('left', this.obj.outerWidth() / 2 + opts.arrowoffset);
              break;
            case 'right':
              css.top =
                target.pos.top +
                target.height / 2 +
                opts.offset.top -
                this.obj.outerHeight() / 2;
              css.left = target.pos.left + target.width + opts.offset.left;
              if (opts.minimumTopPosition !== undefined) {
                arrowCorrection = css.top - opts.minimumTopPosition;
                css.top = Math.max(opts.minimumTopPosition, css.top);
              }
              this.obj
                .find('.arrow')
                .css(
                  'top',
                  this.obj.outerHeight() / 2 +
                    opts.arrowoffset +
                    arrowCorrection
                );
              break;
            case 'bottom':
              css.top = target.pos.top + target.height + opts.offset.top;
              css.left =
                target.pos.left +
                target.width / 2 +
                opts.offset.left -
                this.obj.outerWidth() / 2;
              if (opts.minimumTopPosition !== undefined) {
                arrowCorrection = css.top - opts.minimumTopPosition;
                css.top = Math.max(opts.minimumTopPosition, css.top);
              }
              this.obj
                .find('.arrow')
                .css(
                  'left',
                  this.obj.outerWidth() / 2 + opts.arrowoffset + arrowCorrection
                );
              break;
            case 'bottom-left':
              css.top = target.pos.top + target.height + opts.offset.top;
              css.left = 'auto';
              css.right = opts.offset.right;
              if (opts.minimumTopPosition !== undefined) {
                css.top = Math.max(opts.minimumTopPosition, css.top);
              }
              this.obj
                .find('.arrow')
                .css('left', 'auto')
                .css('right', target.width / 2 - 8 + opts.arrowoffset);
              break;
            case 'bottom-right':
              css.top = target.pos.top + target.height + opts.offset.top;
              css.left = target.pos.left + opts.offset.left;
              if (opts.minimumTopPosition !== undefined) {
                css.top = Math.max(opts.minimumTopPosition, css.top);
              }
              this.obj
                .find('.arrow')
                .css('left', target.width / 2 + opts.arrowoffset);
              break;
            case 'left':
              css.top =
                target.pos.top +
                target.height / 2 +
                opts.offset.top -
                this.obj.outerHeight() / 2;
              css.left =
                target.pos.left + opts.offset.left - this.obj.outerWidth();
              if (opts.minimumTopPosition !== undefined) {
                arrowCorrection = css.top - opts.minimumTopPosition;
                css.top = Math.max(opts.minimumTopPosition, css.top);
              }
              this.obj
                .find('.arrow')
                .css(
                  'top',
                  this.obj.outerHeight() / 2 +
                    opts.arrowoffset +
                    arrowCorrection
                );
              break;
            default:
              css.top = 'auto';
              css.bottom = target.obj.outerHeight() + 12;
              css.left =
                target.pos.left +
                target.width / 2 +
                opts.offset.left -
                this.obj.outerWidth() / 2;
              this.obj
                .find('.arrow')
                .css('left', this.obj.outerWidth() / 2 + opts.arrowoffset);
              break;
          }
        }

        if (opts.customize) {
          css.width = opts.customize.width;
        }

        this.obj.css(css);

        if (opts.showCloseIcon) {
          self.closeIcon.show();

          self.closeIcon.on('click', function() {
            self.close();
          });
        }

        this.options.onInit();

        if (this.options.speechBubble) {
          $(window).resize(function() {
            var css = {};
            calculateSpeechBubblePosition(css);
            self.obj.css(css);
          });
        }

        return this;
      },

      onOutsideClick: function(e) {
        if (!$(e.target).closest('.popover').length) {
          this.hide(true);
        }
      },

      visible: function() {
        return this.obj && this.obj.hasClass('active');
      },

      close: function() {
        var self = this;
        if (this.options && this.options.onBeforeClose) {
          this.options.onBeforeClose();
        }

        if (self.closeIcon) {
          self.closeIcon.off('click');
        }

        if (this.obj && this.obj.hasClass('active')) {
          this.obj.removeClass('active');
          setTimeout(function() {
            self.obj.remove();
            if (self.options.onClose) {
              self.options.onClose();
            }
          }, 200);
        } else {
          if (this.obj) {
            self.obj.remove();
          }
          if (this.options && this.options.onClose) {
            this.options.onClose();
          }
        }
      },

      show: function() {
        var self = this;

        if (!this.obj) {
          this.init();
        }

        this.options.onBeforeShow();

        self.obj.addClass('active');

        if (this.options.speechBubble) {
          var content = self.obj.find('.js-popover-content');
          var width = content.width();
          var height = content.height();
          var top = self.obj[0].style.top;
          var bottom = self.obj[0].style.bottom;
          var left = self.obj[0].style.left;
          var right = self.obj[0].style.right;

          self.obj.css({
            right: right,
            top: top,
            bottom: bottom,
            left: left
          });

          content.css({
            overflow: 'hidden',
            width: self.obj.find('.arrow').hasClass('arrow-center') ? width : 0,
            height: 0
          });

          self.obj
            .removeClass('animate-hide animate-time-1ms animate-delay-1ms')
            .addClass('animate-show animate-time-1ms');

          content.animate(
            {
              width: width,
              height: height
            },
            200,
            'easeOut',
            function() {
              content.css({
                overflow: 'visible'
              });

              self.options.onShow();

              if (
                self.options.speechBubble &&
                self.options.closeOnOutsideClick
              ) {
                $(document).on('click', self.onOutsideClickProxy);
              }
            }
          );
        } else {
          _.defer(function() {
            self.obj.addClass('active');
            self.options.onShow();
            if (
              !self.options.speechBubble &&
              self.options.closeOnOutsideClick
            ) {
              $(document).on('click', self.onOutsideClickProxy);
            }
          });
        }
      },

      hide: function(skipAnimation) {
        var self = this;

        if (this.options && this.options.onBeforeHide) {
          this.options.onBeforeHide();
        }

        if (this.options && this.options.speechBubble && !skipAnimation) {
          var content = self.obj.find('.js-popover-content');
          var width = content.width();

          content.css({
            overflow: 'hidden'
          });

          self.obj
            .removeClass('animate-show animate-time-1ms')
            .addClass('animate-hide animate-time-1ms animate-delay-1ms');

          content.animate(
            {
              width: self.obj.find('.arrow').hasClass('arrow-center') ?
                width :
                0,
              height: 0
            },
            200,
            'easeOut',
            function() {
              if (self.obj) {
                self.obj.removeClass('active');
              }

              content.css({
                width: 'auto',
                height: 'auto'
              });
            }
          );
        } else if (this.obj) {
          this.obj.removeClass('active');
        }

        if (this.options && this.options.onHide) {
          this.options.onHide();
        }

        if (options.closeOnOutsideClick) {
          $(document).off('click', this.onOutsideClickProxy);
        }
      },

      toggle: function() {
        if (this.obj && this.obj.hasClass('active')) {
          this.hide();
        } else {
          this.show();
        }
      },

      reposition: function() {
        var css = {};
        var target = this.target;
        var opts = this.options;

        target.height = target.obj.outerHeight();
        target.width = target.obj.outerWidth();
        target.pos = target.obj.position();

        switch (opts.position) {
          case 'top':
            css.top = 'auto';
            css.bottom = target.obj.outerHeight() + 12;
            css.left =
              target.pos.left +
              target.width / 2 +
              opts.offset.left -
              this.obj.outerWidth() / 2;
            this.obj
              .find('.arrow')
              .css('left', this.obj.outerWidth() / 2 + opts.arrowoffset);
            break;
          case 'right':
            css.top =
              target.pos.top +
              target.height / 2 +
              opts.offset.top -
              this.obj.outerHeight() / 2;
            css.left = target.pos.left + target.width + opts.offset.left;
            this.obj
              .find('.arrow')
              .css('top', this.obj.outerHeight() / 2 + opts.arrowoffset);
            break;
          case 'bottom':
            css.top = target.pos.top + target.height + opts.offset.top;
            css.left =
              target.pos.left +
              target.width / 2 +
              opts.offset.left -
              this.obj.outerWidth() / 2;
            this.obj
              .find('.arrow')
              .css('left', this.obj.outerWidth() / 2 + opts.arrowoffset);
            break;
          case 'left':
            css.top =
              target.pos.top +
              target.height / 2 +
              opts.offset.top -
              this.obj.outerHeight() / 2;
            css.left =
              target.pos.left + opts.offset.left - this.obj.outerWidth();
            this.obj
              .find('.arrow')
              .css('top', this.obj.outerHeight() / 2 + opts.arrowoffset);
            break;
          case 'bottom-left':
            css.top = target.pos.top + target.height + opts.offset.top;
            css.left = 'auto';
            css.right = opts.offset.right;
            if (opts.minimumTopPosition !== undefined) {
              css.top = Math.max(opts.minimumTopPosition, css.top);
            }
            this.obj
              .find('.arrow')
              .css('left', 'auto')
              .css('right', target.width / 2 - 8 + opts.arrowoffset);
            break;
          case 'bottom-right':
            target.height = target.obj.outerHeight();
            target.width = target.obj.outerWidth();
            target.pos = target.obj.position();
            css.top = target.pos.top + target.height + opts.offset.top;
            css.left = target.pos.left + opts.offset.left;
            if (opts.minimumTopPosition !== undefined) {
              css.top = Math.max(opts.minimumTopPosition, css.top);
            }
            this.obj
              .find('.arrow')
              .css('left', target.width / 2 + opts.arrowoffset);
            break;
          default:
            css.top = 'auto';
            css.bottom = target.obj.outerHeight() + 12;
            css.left =
              target.pos.left +
              target.width / 2 +
              opts.offset.left -
              this.obj.outerWidth() / 2;
            this.obj
              .find('.arrow')
              .css('left', this.obj.outerWidth() / 2 + opts.arrowoffset);
            break;
        }

        this.obj.css(css);
      }
    };
  };

  svs.components.Popover.Defaults = {
    title: undefined, 
    content: '', 
    position: 'top', 
    branding: 'popover-default', 
    arrowoffset: 0, 
    offset: {
      top: 0, 
      bottom: 0, 
      left: 0, 
      right: 0 
    },
    closeOnOutsideClick: false,
    speechBubble: false,
    showCloseIcon: false,
    onBeforeClose: function() {},
    onClose: function() {},
    onBeforeShow: function() {},
    onShow: function() {},
    onBeforeHide: function() {},
    onHide: function() {},
    onBeforeInit: function() {},
    onInit: function() {}
  };
})(svs);


 })(window);