(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/pnp-casino/views/pnp-info-page.js') >= 0) return;  svs.modules.push('/modules/pnp-casino/views/pnp-info-page.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.pnp_casino=_svs.pnp_casino||{};
_svs.pnp_casino.templates=_svs.pnp_casino.templates||{};
svs.pnp_casino.templates.pnp_info_page = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-info_page-info_page"),depth0,{"name":"components-casino-info_page-info_page","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['svs-pnp_casino-pnp_info_page'] = svs.pnp_casino.templates.pnp_info_page;
})(svs, Handlebars);


 })(window);