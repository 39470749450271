(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/background-casinopromo/views/casinopromo.js') >= 0) return;  svs.modules.push('/components/banner_layouts/background-casinopromo/views/casinopromo.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.background_casinopromo=_svs.banner_layouts.background_casinopromo||{};
_svs.banner_layouts.background_casinopromo.templates=_svs.banner_layouts.background_casinopromo.templates||{};
svs.banner_layouts.background_casinopromo.templates.casinopromo = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"background-casinopromo grid-row\"\n  data-impression=\"true\"\n  data-name=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n  data-impression-tracking=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":46}}}) : helper)))
    + "\"\n  data-cmsid=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":26}}}) : helper)))
    + "\"\n  data-cmstype=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":31}}}) : helper)))
    + "\"\n  >\n  <div class=\"background-casinopromo-holder col-xs-12\">\n    <div class=\"casinopromo-content grid-row "
    + alias1(((helper = (helper = lookupProperty(helpers,"layoutOrder") || (depth0 != null ? lookupProperty(depth0,"layoutOrder") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"layoutOrder","hash":{},"data":data,"loc":{"start":{"line":12,"column":45},"end":{"line":12,"column":60}}}) : helper)))
    + " "
    + alias1(((helper = (helper = lookupProperty(helpers,"themeClass") || (depth0 != null ? lookupProperty(depth0,"themeClass") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"themeClass","hash":{},"data":data,"loc":{"start":{"line":12,"column":61},"end":{"line":12,"column":75}}}) : helper)))
    + "\">\n      <div class=\"casinopromo-image col-xs-12 col-md-6\">\n        <div class=\"js-casinopromo-badge-holder casinopromo-badge\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-casino_promo_actions-badge"),(depth0 != null ? lookupProperty(depth0,"game") : depth0),{"name":"components-banner_actions-casino_promo_actions-badge","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias2,(depth0 != null ? lookupProperty(depth0,"game") : depth0),{"name":"with","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":23,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"hasImages") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"casinopromo-text col-xs-12 col-md-6\">\n        <div class=\"casinopromo-text-inner\">\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"provider") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":10},"end":{"line":41,"column":100}}})) != null ? stack1 : "")
    + "\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"title") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":10},"end":{"line":42,"column":100}}})) != null ? stack1 : "")
    + "\n          <div class=\"casinopromo-text-info-props-wrapper\">\n            <div class=\"casinopromo-text-info-props\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"lobbyType") : stack1),"===","live",{"name":"compare","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":14},"end":{"line":55,"column":26}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"rtp") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":14},"end":{"line":58,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"winLines") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":14},"end":{"line":61,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n          <div class=\"casinopromo-play-button js-casinopromo-play-button\">\n            <div class=\"js-casinopromo-text-btn-play casinopromo-text-btn-play\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-casino_promo_actions-play"),depth0,{"name":"components-banner_actions-casino_promo_actions-play","hash":{"isPnpSite":((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"isPnpSite") : stack1)},"data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lobbyType") : depth0),"===","live",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":10},"end":{"line":22,"column":22}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"js-casinopromo-roulette-numbers casinopromo-roulette-numbers\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-casino_promo_actions-roulette_numbers"),(depth0 != null ? lookupProperty(depth0,"game") : depth0),{"name":"components-banner_actions-casino_promo_actions-roulette_numbers","data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":27,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":10},"end":{"line":30,"column":17}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"landscapeImage") : depth0),{"name":"components-content-image_v2-image","hash":{"cssSelectors":"image-wrapper-fit imagev2-legacy background-image-wrapper"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"portraitImage") : depth0),{"name":"components-content-image_v2-image","hash":{"cssSelectors":"image-wrapper-fit imagev2-legacy background-image-wrapper"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lobbySvgImage") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":36,"column":17}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"lobbySvgImage") : stack1),{"name":"components-content-image_v2-image","hash":{"cssSelectors":""},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"lobbyImage") : stack1),{"name":"components-content-image_v2-image","hash":{"cssSelectors":""},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"casinopromo-text-provider\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"provider") : stack1), depth0))
    + "</div>";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"casinopromo-text-title\"><h2>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h2></div>";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"js-casinopromo-live-number-of-seats\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-casino_promo_actions-number_of_seats"),(depth0 != null ? lookupProperty(depth0,"game") : depth0),{"name":"components-banner_actions-casino_promo_actions-number_of_seats","data":data,"indent":"                  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"js-casinopromo-live-number-of-players\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-casino_promo_actions-number_of_players"),(depth0 != null ? lookupProperty(depth0,"game") : depth0),{"name":"components-banner_actions-casino_promo_actions-number_of_players","data":data,"indent":"                  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"js-casinopromo-live-betdetails\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-casino_promo_actions-betdetails"),(depth0 != null ? lookupProperty(depth0,"game") : depth0),{"name":"components-banner_actions-casino_promo_actions-betdetails","data":data,"indent":"                  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"suffix":"%","infoText":((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"rtp") : stack1),"title":"RTP"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"suffix":" st","infoText":(lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"winLines") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":60,"column":98},"end":{"line":60,"column":128}}}),"title":"Vinstlinjer"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "  <div>No game</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"game") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":77,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_layouts-background_casinopromo-casinopromo'] = svs.banner_layouts.background_casinopromo.templates.casinopromo;
})(svs, Handlebars);


 })(window);