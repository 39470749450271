(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/placeholder/views/placeholder.js') >= 0) return;  svs.modules.push('/components/casino/placeholder/views/placeholder.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.placeholder=_svs.casino.placeholder||{};
_svs.casino.placeholder.templates=_svs.casino.placeholder.templates||{};
svs.casino.placeholder.templates.placeholder = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a href="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"buttonLink") || (depth0 != null ? lookupProperty(depth0,"buttonLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"buttonLink","hash":{},"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":28}}}) : helper)))
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttonSize") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "      </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"title":(depth0 != null ? lookupProperty(depth0,"buttonText") : depth0),"focus":true,"size":(depth0 != null ? lookupProperty(depth0,"buttonSize") : depth0)},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":69}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"title":(depth0 != null ? lookupProperty(depth0,"buttonText") : depth0),"focus":true,"size":300},"data":data,"loc":{"start":{"line":11,"column":10},"end":{"line":11,"column":62}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"casino-placeholder\" class=\"grid-row casino-placeholder-container\">\n  <div class=\"casino-placeholder col-xs-12\">\n    "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"icon":(depth0 != null ? lookupProperty(depth0,"icon") : depth0),"class":"casino-placeholder-icon","size":(depth0 != null ? lookupProperty(depth0,"iconSize") : depth0)},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":73}}}))
    + "\n    <h2 class=\"casino-placeholder-title\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":41},"end":{"line":4,"column":50}}}) : helper)))
    + "</h2>\n    <p class=\"casino-placeholder-information\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"information") || (depth0 != null ? lookupProperty(depth0,"information") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"information","hash":{},"data":data,"loc":{"start":{"line":5,"column":46},"end":{"line":5,"column":61}}}) : helper)))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttonConfig") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-casino-placeholder-placeholder'] = svs.casino.placeholder.templates.placeholder;
})(svs, Handlebars);


 })(window);