(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/assets/javascripts/info-play-buttons.js') >= 0) return;  svs.modules.push('/components/casino/info-page/assets/javascripts/info-play-buttons.js');
"use strict";


class InfoPlayButtons {
  constructor(game) {
    this.el = document.getElementsByClassName('js-casino-info-play-buttons')[0];
    this.gameModel = game;
    this.addListeners();
  }
  addListeners() {
    var _this$el;
    this.gameModel.on('change', this, this.refresh);
    (_this$el = this.el) === null || _this$el === void 0 || _this$el.addEventListener('click', e => {
      e.target.classList.contains('js-casino-info-play') && this.handlePlayButtonClick(false, e);
      e.target.classList.contains('js-casino-info-demoplay') && this.handlePlayButtonClick(true, e);
    });
  }
  handlePlayButtonClick(isDemo, e) {
    e.preventDefault();
    e.stopPropagation();
    svs.components.casino.shared.startGame(isDemo ? 'provspela' : 'spela', this.gameModel.name, this.getPromoData(e), this.gameModel);
  }
  getPromoData(e) {
    const position = e.target.dataset.areaName || 'no-origin';
    return {
      name: this.gameModel.name,
      title: "".concat(this.gameModel.provider, ";").concat(this.gameModel.name),
      position
    };
  }
  refresh(changes) {
    if (changes.closed) {
      const playButton = this.el.querySelector('.js-casino-info-play');
      if (playButton) {
        if (this.gameModel.closed) {
          playButton.classList.add('disabled');
        } else {
          playButton.classList.remove('disabled');
        }
      }
    }
  }
  destroy() {
    this.gameModel.off('change', this);
  }
}
setGlobal('svs.components.casino.InfoPlayButtons', InfoPlayButtons);

 })(window);