(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/views/info-disabled.js') >= 0) return;  svs.modules.push('/components/casino/info-page/views/info-disabled.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.info_page=_svs.casino.info_page||{};
_svs.casino.info_page.templates=_svs.casino.info_page.templates||{};
svs.casino.info_page.templates.info_disabled = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "game-info-isfavorite";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<img src=\"/images/casino/blur-unavailable-small.jpg\" class=\"casino-info-bg\"/>\n<div class=\"game-info-disabled\">\n  <div class=\"game-info-disabled-emptyimg-content\">\n    <div>\n      "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"color":"fc-casino","icon":"eye-closed","size":"600"},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":66}}}))
    + "\n      <p class=\"fc-casino f-500 margin-xs-top-1 f-uppercase f-bold\">Otillgängligt spel</p>\n    </div>\n  </div>\n</div>\n\n<button data-name=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":12,"column":19},"end":{"line":12,"column":27}}}) : helper)))
    + "\"\n        class=\"icon-button game-info-favorite fc-white f-uppercase js-game-info-favorite "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"favorite") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":89},"end":{"line":14,"column":47}}})) != null ? stack1 : "")
    + "\">\n  <span class=\"game-info-favorite-filled\">Ta bort favorit <i class=\"icon-default icon-favorites-filled\"></i></span>\n  <span class=\"game-info-favorite-empty\">Spara favorit <i class=\"icon-default icon-favorites\"></i></span>\n</button>\n\n<p class=\"f-600 game-info-description padding-2 padding-top-1 margin-0\">\n  Spelet är antingen tillfälligt avstängt <br> eller så har det tagits bort.\n</p>\n\n";
},"useData":true});
Handlebars.partials['components-casino-info_page-info_disabled'] = svs.casino.info_page.templates.info_disabled;
})(svs, Handlebars);


 })(window);