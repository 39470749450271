(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/assets/javascripts/info-details.js') >= 0) return;  svs.modules.push('/components/casino/info-page/assets/javascripts/info-details.js');
"use strict";


class InfoDetails {
  constructor(game) {
    this.$el = $('.js-casino-details');
    this.gameModel = game;
    this.addListeners();
    this.template = svs.casino.info_page.templates.info_details;
    this.relevantChanges = ['jackpotAmount', 'openHours', 'betLimits'];
    this.subscribeToSockets();
  }
  subscribeToSockets() {
    if (!this.isSubscribed) {
      setTimeout(() => {
        this.gameModel.subscribeToSockets();
        this.isSubscribed = true;
      }, 200);
    }
  }
  unsubscribeToSockets() {
    if (this.isSubscribed) {
      this.gameModel.unSubscribeToSockets();
      this.isSubscribed = false;
    }
  }
  addListeners() {
    this.gameModel.on('change', this, this.refresh);
  }
  refresh(changed) {
    for (const change of this.relevantChanges) {
      if (changed[change]) {
        this.$el.replaceWith(this.template(this.gameModel));
        return;
      }
    }
  }
  destroy() {
    this.unsubscribeToSockets();
    this.gameModel.off('change', this);
  }
}
setGlobal('svs.components.casino.InfoDetails', InfoDetails);

 })(window);