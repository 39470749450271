(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/pub-sub/assets/javascripts/pub-sub.js') >= 0) return;  svs.modules.push('/components/casino/pub-sub/assets/javascripts/pub-sub.js');

'use strict';

class PubSub {
  constructor() {
    this.callbacks = {};
    this.subscriptions = 0;
    this.log = new svs.core.log.Logger('components:casino:pubSub');
    this.EXCESS_SUBSCRIPTIONS = 200;
  }
  emit(event, data) {
    if (this.callbacks[event]) {
      this.callbacks[event].forEach(callback => callback.callback.call(callback.context, data));
    }
  }
  on(event, context, callback) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    if (this.findIndex(event, context) < 0) {
      this.sanityCheck(1);
      this.subscriptions++;
      this.callbacks[event].push({
        context,
        callback
      });
    }
  }
  findIndex(event, context) {
    if (this.callbacks[event]) {
      for (let i = 0; i < this.callbacks[event].length; i++) {
        if (this.callbacks[event][i].context === context) {
          return i;
        }
      }
    }
    return -1;
  }
  off(event, context) {
    const i = this.findIndex(event, context);
    if (i >= 0) {
      this.sanityCheck(-1);
      this.subscriptions--;
      this.callbacks[event].splice(i, 1);
    }
  }
  sanityCheck(diff) {
    svs.components.casino.PubSubCounter = (svs.components.casino.PubSubCounter || 0) + diff;
    if (svs.components.casino.PubSubCounter < 0 || svs.components.casino.PubSubCounter > this.EXCESS_SUBSCRIPTIONS) {
      if (!svs.components.casino.PubSubExcessWarningSent) {
        svs.components.casino.PubSubExcessWarningSent = true;
        this.log.warn('Unsound number of subscriptions:', svs.components.casino.PubSubCounter);
      }
    }
  }
}

setGlobal('svs.components.casino.PubSub', PubSub);

 })(window);