(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/favorites/assets/javascripts/favorites.js') >= 0) return;  svs.modules.push('/components/casino/favorites/assets/javascripts/favorites.js');
'use strict';

class Favorites extends svs.components.casino.PubSub {
  constructor() {
    super();
    this.log = new svs.core.log.Logger('component:casino:favorites');
    this.MAX_FAVORITES = 20;
    this.init();
  }
  init() {
    if (!this.favorites) {
      this.favorites = svs.components.casino.data.favorites;
      $(() => {
        this.emitFavorites();
      });
    }
  }
  emitFavorites() {
    if (this.favorites) {
      this.favorites.forEach(favorite => {
        this.emit("change-".concat(favorite), true);
      });
      this.emit('change', this.favorites);
    }
  }
  async lock() {
    if (this.lockPromise) {
      await this.lockPromise;
    }
    this.lockPromise = new Promise(resolve => {
      this.lockPromiseResolver = resolve;
    });
  }
  unlock() {
    this.lockPromiseResolver();
    delete this.lockPromise;
  }
  async load() {
    await this.lock();
    if (!this.favorites) {
      const options = {
        path: '/casino/1/favorites',
        method: 'GET'
      };
      const response = await this.callJupiter(options);
      this.favorites = response.favorites;
    }
    this.unlock();
  }
  async save() {
    await this.lock();
    const options = {
      path: "/casino/1/favorites?favorites=".concat(encodeURIComponent(this.favorites.join(';'))),
      method: 'PUT'
    };
    await this.callJupiter(options);
    this.unlock();
  }
  async get() {
    if (this.isLoggedIn()) {
      await this.load();
      return this.favorites;
    }
    return [];
  }
  async add(gameName) {
    await this.load();
    if (!(await this.isFavorite(gameName))) {
      if (this.favorites.length === this.MAX_FAVORITES) {
        this.maxFavorites();
        return;
      }
      this.favorites.unshift(gameName);
      this.updateGame(gameName, true);
      return this.save();
    }
  }
  async remove(gameName) {
    await this.load();
    if (await this.isFavorite(gameName)) {
      this.favorites.splice(this.favorites.indexOf(gameName), 1);
      this.updateGame(gameName, false);
      return this.save();
    }
  }
  updateGame(gameName, state) {
    this.trackEvent(gameName, state);
    this.emit("change-".concat(gameName), state);
    this.emit('change', [gameName]);
  }
  trackEvent(gameName, state) {
    const eventData = {
      category: 'Casino',
      event: 'click',
      action: state ? 'Add Favorite' : 'Remove Favorite',
      opt_label: gameName,
      opt_value: state
    };
    svs.components.analytics.trackEvent && svs.components.analytics.trackEvent(eventData);
  }
  async set(gameName, state) {
    if (this.isLoggedIn()) {
      if (state) {
        this.add(gameName);
      } else {
        this.remove(gameName);
      }
    }
  }
  async isFavorite(gameName) {
    if (this.isLoggedIn()) {
      await this.load();
      return this.favorites.indexOf(gameName) > -1;
    }
    return false;
  }
  isLoggedIn() {
    return svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  }
  async callJupiter(options) {
    return new Promise((resolve, reject) => {
      svs.core.jupiter.call(options, data => {
        if (data.error) {
          this.log.error('Error in jupiter response:', data.error);
          reject(Error('Unexpected error in response'));
        } else {
          resolve(data);
        }
      }, err => {
        this.log.error('Error calling jupiter:', err);
        reject(Error('Unexpected communications error'));
      });
    });
  }
  maxFavorites() {
    const isMyCasino = /\/mina-spel/.test(window.location);
    const isPnp = svs.core.config.data.svsconfig.site === 'momang';
    let title = 'Maximalt antal favoriter uppnått';
    let message = {
      type: svs.ui.dialog.message.TEXT,
      text: "Det g\xE5r att spara upp till 20 st favoriter. Rensa bland dina favoriter ".concat(isMyCasino ? '' : 'under Mina spel ', "f\xF6r att kunna l\xE4gga till fler.")
    };
    if (isPnp) {
      title = 'Du kan max ha 20 favoriter';
      message = {
        type: svs.ui.dialog.message.TEXT,
        text: 'För att spara en ny favorit behöver du avmarkera en sparad favorit.'
      };
    }
    const dialogOptions = {
      branding: isPnp ? svs.ui.dialog.branding.MOMANG : svs.ui.dialog.branding.SPORT,
      title,
      icon: 'favorites',
      area: svs.ui.dialog.area.POPUP,
      type: svs.ui.dialog.type.DEFAULT,
      product: 'Casino',
      message: [message],
      actions: [{
        title: 'Okej',
        callback: () => {
          dialog.close();
        }
      }]
    };
    if (!isPnp && !isMyCasino) {
      dialogOptions.actions.push({
        title: 'Till Mina spel',
        callback: () => {
          svs.utils.url.navigate("".concat(svs.core.data.baseUrl, "/mina-spel"));
          dialog.close();
        }
      });
    } else if (isPnp) {
      dialogOptions.actions.push({
        title: 'Till Mina Favoriter',
        callback: () => {
          svs.utils.url.navigate(svs.core.urlMapping.get('pnpHome'));
          dialog.close();
        }
      });
    }
    const dialog = new svs.ui.dialog.Confirm(dialogOptions);
    svs.ui.dialog.api.add(dialog);
  }
}
setGlobal('svs.components.casino.favorites', new Favorites());
setGlobal('svs.components.casino.favorites.Test', Favorites);

 })(window);