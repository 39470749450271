(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/animate-header/assets/javascripts/animate-header.js') >= 0) return;  svs.modules.push('/components/casino/animate-header/assets/javascripts/animate-header.js');

'use strict';

const isMobile = svs.core.detect.formfactor.mobile();
const topContentWrapper = document.querySelector('.js-top-content-wrapper');

const leavingTopDistance = '+=50';
let mobileScrollTrigger;
const SCROLL_VELOCITY = -100; 

const mainTimeline = gsap.timeline({
  scrollTrigger: {
    trigger: document.querySelector('.pnp-background'),
    scrub: true,
    start: '0',
    end: leavingTopDistance,
    onUpdate: self => {
      if (isMobile && self.scroll() === 0) {
        topContentWrapper.classList.remove('animate-show-header', 'fixed-top-content', 'animation-active');
      }
    }
  }
});
function init() {
  if (isMobile) {
    mobileScrollTrigger = ScrollTrigger.create({
      start: leavingTopDistance,
      onUpdate: self => {
        if (self.direction === -1) {
          self.getVelocity() < SCROLL_VELOCITY && topContentWrapper.classList.add('fixed-top-content', 'animate-show-header');
        } else {
          topContentWrapper.classList.remove('animate-show-header');
        }
      },
      onToggle: self => {
        if (self.isActive) {
          topContentWrapper.classList.add('fixed-top-content', 'animation-active');
        }
      }
    });
  }
}

function disable() {
  let revert = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  mainTimeline.scrollTrigger.disable(revert);
  if (mobileScrollTrigger) {
    mobileScrollTrigger.disable(revert);
  }
}

function enable() {
  let reset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  mainTimeline.scrollTrigger.enable(reset);
  if (mobileScrollTrigger) {
    mobileScrollTrigger.enable(reset);
  }
}
setGlobal('svs.components.casino.animateHeader.init', init);
setGlobal('svs.components.casino.animateHeader.disable', disable);
setGlobal('svs.components.casino.animateHeader.enable', enable);

 })(window);