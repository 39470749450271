(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/assets/javascripts/cms-article.js') >= 0) return;  svs.modules.push('/components/casino/info-page/assets/javascripts/cms-article.js');
"use strict";


class CmsArticle {
  constructor(game) {
    this.el = $('.js-casino-cms-article');
    this.gameModel = game;
    this.template = svs.casino.info_page.templates.cms_article;
    this.addListeners();
    if (this.gameModel.articleId && !this.gameModel.article) {
      new svs.ui.Loader('.js-casino-cms-article-loader').show(200);
      this.gameModel.fetchArticle();
    }
  }
  addListeners() {
    this.gameModel.on('change', this, this.refresh);
  }
  refresh(changed) {
    if (changed.article) {
      this.el.replaceWith(this.template({
        article: this.gameModel.article,
        gameName: this.gameModel.name
      }));
    }
  }
  destroy() {
    this.gameModel.off('change', this);
  }
}
setGlobal('svs.components.casino.CmsArticle', CmsArticle);

 })(window);