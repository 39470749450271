(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/views/info-page.js') >= 0) return;  svs.modules.push('/components/casino/info-page/views/info-page.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.info_page=_svs.casino.info_page||{};
_svs.casino.info_page.templates=_svs.casino.info_page.templates||{};
svs.casino.info_page.templates.info_page = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div id=\"info-page\" class=\"casino-info-page\" itemScope itemType=\"http://schema.org/Product\">\n    <div class=\"casino-info-page-contentholder\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-sub_header-sub_header"),depth0,{"name":"components-casino-sub_header-sub_header","hash":{"title":(depth0 != null ? lookupProperty(depth0,"title") : depth0),"returnUrl":(lookupProperty(helpers,"returnUrl")||(depth0 && lookupProperty(depth0,"returnUrl"))||container.hooks.helperMissing).call(alias1,{"name":"returnUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":149},"end":{"line":4,"column":160}}}),"isInfoRoute":((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isInfoRoute")),"pnpHome":((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"pnpHome")),"isPnp":((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isPnp")),"baseUrl":((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"baseUrl"))},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-info_disabled"),depth0,{"name":"components-casino-info_disabled","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <meta itemProp=\"category\" content=\"Casino Slots\"/>\n        <meta itemProp=\"mpn\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":46}}}) : helper)))
    + "\"/>\n        <meta itemProp=\"brand\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"provider") || (depth0 != null ? lookupProperty(depth0,"provider") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"provider","hash":{},"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":52}}}) : helper)))
    + "\"/>\n        <meta itemprop=\"name\" content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":11,"column":39},"end":{"line":11,"column":48}}}) : helper)))
    + "\"/>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-info_page-info_image"),depth0,{"name":"components-casino-info_page-info_image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-info_page-info_details"),depth0,{"name":"components-casino-info_page-info_details","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-info_page-info_play_buttons"),depth0,{"name":"components-casino-info_page-info_play_buttons","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-info_page-info_description"),depth0,{"name":"components-casino-info_page-info_description","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-info_page-cms_article"),depth0,{"name":"components-casino-info_page-cms_article","hash":{"article":(depth0 != null ? lookupProperty(depth0,"article") : depth0),"gameName":(depth0 != null ? lookupProperty(depth0,"name") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disclaimer") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <p class=\"casino-info-description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"disclaimer") || (depth0 != null ? lookupProperty(depth0,"disclaimer") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"disclaimer","hash":{},"data":data,"loc":{"start":{"line":18,"column":45},"end":{"line":18,"column":61}}}) : helper))) != null ? stack1 : "")
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <p class=\"casino-info-description\"> För detta spel gäller <a title=\"Klicka här för att läsa villkoren\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"getCasinoGameTermsDocumentLink") || (depth0 != null ? lookupProperty(depth0,"getCasinoGameTermsDocumentLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"getCasinoGameTermsDocumentLink","hash":{},"data":data,"loc":{"start":{"line":20,"column":119},"end":{"line":20,"column":153}}}) : helper)))
    + "\" target=\"_blank\">\n            Spelregler för Casino och datorsimulerade automatspel</a> samt de spelregler som återfinns på spelets spelsida (Spelspecifika Villkor).\n            För det fall texten ovan skiljer sig från de Spelspecifika Villkoren äger de senare företräde.</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentGame") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":27,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-casino-info_page-info_page'] = svs.casino.info_page.templates.info_page;
})(svs, Handlebars);


 })(window);