(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/background-casinopromo/assets/javascripts/init-actions.js') >= 0) return;  svs.modules.push('/components/banner_layouts/background-casinopromo/assets/javascripts/init-actions.js');
"use strict";


const {
  LiveDataView,
  PlayButton
} = svs.banner_actions.casino_promo_actions;
const init = () => {
  const liveDataViews = {};
  const PlayButtons = {};
  $('.background-casinopromo').each((i, el) => {
    const gameName = $(el).data('name');
    const game = svs.casino_promo.data["game_".concat(gameName.replace(/-/g, '_'))];
    const gameModel = new svs.components.casino.game.GameModel(game);
    liveDataViews[gameName] = new LiveDataView(game, gameModel, el, false);
    PlayButtons[gameName] = new PlayButton(gameModel, el);
  });
};
init();

 })(window);