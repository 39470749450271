(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/router/assets/javascripts/router.js') >= 0) return;  svs.modules.push('/components/casino/router/assets/javascripts/router.js');

'use strict';

class Router {
  constructor(routes, routeFuncs) {
    var _svs$pnp_casino, _svs$lb_casino;
    this.routes = routes;
    this.routeFuncs = routeFuncs;
    this.sortedRoutes = Object.keys(routes);
    this.sortedRoutes.sort().reverse();
    this.titleNode = document.querySelector('title');
    this.metaTwitterTitle = document.querySelector('meta[name="twitter:title"]');
    this.metaOgTitle = document.querySelector('meta[property="og:title"]');
    this.metaDescription = document.querySelector('meta[name="description"]');
    this.metaTwitterDescription = document.querySelector('meta[name="twitter:description"]');
    this.metaOgDescription = document.querySelector('meta[property="og:description"]');
    this.defaultDescription = 'Upptäck vårt stora utbud av slotsspel, bordsspel, live casino och andra casinospel online hos Svenska Spel. Välkommen till Svenska Spels onlinecasino!';
    this.log = new svs.core.log.Logger('svs.components.casino.router');
    const isInfoRoute = ((_svs$pnp_casino = svs.pnp_casino) === null || _svs$pnp_casino === void 0 || (_svs$pnp_casino = _svs$pnp_casino.data) === null || _svs$pnp_casino === void 0 || (_svs$pnp_casino = _svs$pnp_casino.renderModel) === null || _svs$pnp_casino === void 0 ? void 0 : _svs$pnp_casino.isInfoRoute) || ((_svs$lb_casino = svs.lb_casino) === null || _svs$lb_casino === void 0 || (_svs$lb_casino = _svs$lb_casino.data) === null || _svs$lb_casino === void 0 || (_svs$lb_casino = _svs$lb_casino.renderModel) === null || _svs$lb_casino === void 0 ? void 0 : _svs$lb_casino.isInfoRoute);
    const popstateFunc = isInfoRoute ? () => window.location.reload() : () => this.onNavigation();
    window.addEventListener('popstate', popstateFunc);
  }
  updateMetaData(metaData) {
    if (metaData.title) {
      this.titleNode.innerHTML = metaData.title;
      this.metaTwitterTitle.setAttribute('content', metaData.title);
      this.metaOgTitle.setAttribute('content', metaData.title);
    }
    if (metaData.description) {
      this.metaDescription.setAttribute('content', metaData.description);
      this.metaTwitterDescription.setAttribute('content', metaData.description);
      this.metaOgDescription.setAttribute('content', metaData.description);
    }
  }
  navigate(url) {
    let replace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    let state = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let metaData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
      title: document.title,
      description: this.defaultDescription
    };
    let passTempData = arguments.length > 4 ? arguments[4] : undefined;
    this.previousTitle = this.titleNode.innerHTML;
    this.previousDescription = this.metaDescription.getAttribute('content');
    if (replace) {
      history.replaceState && history.replaceState(state, metaData.title, url);
    } else {
      history.pushState && history.pushState(state, metaData.title, url);
    }

    try {
      this.updateMetaData(metaData);
    } catch (e) {
      this.log.info('Error setting page title', e);
    }
    this.onNavigation(passTempData);
  }
  onNavigation(passTempData) {
    const casinoBasePath = svs.core.data.baseOriginalUrl.replace(/\/$/, '');
    const casinoPath = window.location.pathname.replace(new RegExp("^".concat(casinoBasePath)), '');
    this.sortedRoutes.every(route => {
      const data = new RegExp("^".concat(route)).exec(decodeURIComponent(casinoPath));
      if (data) {
        this.routeFuncs && this.routeFuncs[this.routes[route]] && this.routeFuncs[this.routes[route]].call(this, data, passTempData);
        return false;
      }
      return true;
    });
  }
  back() {
    history.back();
    this.updateMetaData({
      title: this.previousTitle,
      description: this.previousDescription
    });
  }
}

setGlobal('svs.components.casino.Router', Router);

 })(window);