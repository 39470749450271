(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/assets/javascripts/live-info.js') >= 0) return;  svs.modules.push('/components/casino/game/assets/javascripts/live-info.js');

'use strict';

function tableClosed(data) {
  return data.gameState !== 1 && data.openHours && data.openHours.type !== 'FullTime';
}
function interpretPlaytechLivedata(data) {
  if (data.seatsTaken) {
    let seatsLeft = 0;
    data.seatsTaken.forEach(seat => {
      seatsLeft = seatsLeft + seat;
    });
    data.betBehind = seatsLeft === 0;
  }
  return data;
}
function interpretAuthenticLivedata(data) {
  var _data$seatsTaken;
  if (((_data$seatsTaken = data.seatsTaken) === null || _data$seatsTaken === void 0 ? void 0 : _data$seatsTaken.length) === 0) {
    data.seatsTaken = null;
  }
  return data;
}
function interpretEvolutionLivedata(data) {
  if (data.results && data.results.length && data.results[0].indexOf('|') > 0) {
    let r = [];
    data.results.forEach(v => {
      r = r.concat(v.split('|'));
    });
    data.results = r;
  }
  return data;
}
function interpretLiveData(data) {
  data.closed = tableClosed(data);
  switch (data.provider) {
    case 'Evolution':
      data = interpretEvolutionLivedata(data);
      break;
    case 'Playtech':
      data = interpretPlaytechLivedata(data);
      break;
    case 'Authentic':
      data = interpretAuthenticLivedata(data);
      break;
    default:
      break;
  }
  return data;
}

setGlobal('svs.components.casino.game.interpretLiveData', interpretLiveData);

 })(window);