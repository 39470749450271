(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/casino-promo-actions/views/number-of-players.js') >= 0) return;  svs.modules.push('/components/banner_actions/casino-promo-actions/views/number-of-players.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_actions=_svs.banner_actions||{};
_svs.banner_actions.casino_promo_actions=_svs.banner_actions.casino_promo_actions||{};
_svs.banner_actions.casino_promo_actions.templates=_svs.banner_actions.casino_promo_actions.templates||{};
svs.banner_actions.casino_promo_actions.templates.number_of_players = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"closed") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"infoText":(lookupProperty(helpers,"getSeatsLeft")||(depth0 && lookupProperty(depth0,"getSeatsLeft"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"getSeatsLeft","hash":{},"data":data,"loc":{"start":{"line":4,"column":84},"end":{"line":4,"column":103}}}),"title":"Platser"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"suffix":" st","infoText":(lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"players") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":6,"column":84},"end":{"line":6,"column":108}}}),"title":"Spelare"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"seatsTaken") : depth0),"!==",null,{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":21,"column":16}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"closed") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":14,"column":13}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"infoText":(lookupProperty(helpers,"getSeatsLeft")||(depth0 && lookupProperty(depth0,"getSeatsLeft"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"getSeatsLeft","hash":{},"data":data,"loc":{"start":{"line":11,"column":86},"end":{"line":11,"column":105}}}),"title":"Platser"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"suffix":" st","infoText":(lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"players") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":13,"column":86},"end":{"line":13,"column":110}}}),"title":"Spelare"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"closed") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":6},"end":{"line":20,"column":17}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"players") : depth0),"!==",null,{"name":"compare","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":19,"column":20}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"suffix":" st","infoText":(lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"players") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":18,"column":88},"end":{"line":18,"column":112}}}),"title":"Spelare"},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"casino-game-promo-available-seats casino-quick-info-prop js-casino-game-live-seats\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"liveCategory") : depth0),"===","blackjack",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":22,"column":14}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_actions-casino_promo_actions-number_of_players'] = svs.banner_actions.casino_promo_actions.templates.number_of_players;
})(svs, Handlebars);


 })(window);