(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/assets/javascripts/info-image.js') >= 0) return;  svs.modules.push('/components/casino/info-page/assets/javascripts/info-image.js');
"use strict";


class InfoImage {
  constructor(game) {
    var _this$el;
    this.el = document.querySelector('.js-casino-info-image-container');
    this.gameModel = game;
    this.favoriteBtn = (_this$el = this.el) === null || _this$el === void 0 ? void 0 : _this$el.querySelector('.js-casino-game-favorite-button');
    this.init();
  }
  async init() {
    this.addListeners();
    this.toggleFavorite(await svs.components.casino.favorites.isFavorite(this.gameModel.name));
  }
  addListeners() {
    var _this$favoriteBtn;
    (_this$favoriteBtn = this.favoriteBtn) === null || _this$favoriteBtn === void 0 || _this$favoriteBtn.addEventListener('click', async () => await this.setFavorite());
    svs.components.casino.favorites.on("change-".concat(this.gameModel.name), this, this.toggleFavorite);
  }
  async setFavorite() {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      svs.utils.url.navigate(svs.core.urlMapping.get('loginHome'));
      return;
    }
    svs.components.casino.favorites.set(this.gameModel.name, !(await svs.components.casino.favorites.isFavorite(this.gameModel.name)));
  }
  toggleFavorite(state) {
    var _this$favoriteBtn2, _this$favoriteBtn3;
    const titleName = state ? 'Avmarkera som favorit' : 'Lägg till som favorit';
    (_this$favoriteBtn2 = this.favoriteBtn) === null || _this$favoriteBtn2 === void 0 || _this$favoriteBtn2.classList.toggle('casino-game-is-favorite', state);
    (_this$favoriteBtn3 = this.favoriteBtn) === null || _this$favoriteBtn3 === void 0 || _this$favoriteBtn3.setAttribute('title', titleName);
  }
  refresh(changes) {
    if (changes.closed) {
      this.el.find('.js-casino-info-closed').toggleClass('hide', !this.gameModel.closed);
    }
  }
  removeListeners() {
    svs.components.casino.favorites.off("change-".concat(this.gameModel.name), this);
    this.favoriteBtn.removeEventListener('click', this.setFavorite);
  }
  destroy() {
    this.removeListeners();
  }
}
setGlobal('svs.components.casino.InfoImage', InfoImage);

 })(window);