(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/casino-promo-actions/views/play.js') >= 0) return;  svs.modules.push('/components/banner_actions/casino-promo-actions/views/play.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_actions=_svs.banner_actions||{};
_svs.banner_actions.casino_promo_actions=_svs.banner_actions.casino_promo_actions||{};
_svs.banner_actions.casino_promo_actions.templates=_svs.banner_actions.casino_promo_actions.templates||{};
svs.banner_actions.casino_promo_actions.templates.play = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"openHours") : depth0)) != null ? lookupProperty(stack1,"startTime") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h2 class=\"f-400 casino-game-live-title fc-white f-600 margin-bottom-0\">Öppnar klockan "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"openHours") : depth0)) != null ? lookupProperty(stack1,"startTime") : stack1), depth0))
    + "</h2>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "    <h2 class=\"f-400 casino-game-live-title fc-white f-600 margin-bottom-0\">Spelet är stängt</h2>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":16,"column":9}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"class":"margin-bottom-1 game-promo-btn-play js-game-promo-btn-play js-casino-track-click","block":true,"disabled":true,"size":300},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":156}}})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "Spela";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isPnpSite") || (depth0 != null ? lookupProperty(depth0,"isPnpSite") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isPnpSite","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":15,"column":18}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isPnpSite")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"margin-bottom-1 js-game-promo-btn-play js-casino-track-click","block":true,"size":300,"rel":"nofollow","href":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"/spela/",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":47}}})},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":12,"column":173}}})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"linkTitle") || (depth0 != null ? lookupProperty(depth0,"linkTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"linkTitle","hash":{},"data":data,"loc":{"start":{"line":12,"column":153},"end":{"line":12,"column":166}}}) : helper)));
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"margin-bottom-1 game-promo-btn-play js-game-promo-btn-play js-casino-track-click","block":true,"size":300,"rel":"nofollow","href":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"casinoHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":57}}}),"/spela/",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":14,"column":26},"end":{"line":14,"column":73}}})},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":14,"column":219}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"closed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "")
    + "\n\n\n\n\n\n\n\n";
},"useData":true});
Handlebars.partials['components-banner_actions-casino_promo_actions-play'] = svs.banner_actions.casino_promo_actions.templates.play;
})(svs, Handlebars);


 })(window);