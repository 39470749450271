(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/casino-init/assets/javascripts/casino.js') >= 0) return;  svs.modules.push('/components/casino/casino-init/assets/javascripts/casino.js');
"use strict";


class Casino {
  constructor() {
    window.addEventListener('unload', () => {});
    this.isMobile = svs.core.detect.formfactor.mobile();
    this.shared = svs.components.casino.shared;
    this.isPnp = svs.core.config.data.svsconfig.site === 'momang';
    svs.components.casino.router = new svs.components.casino.Router(svs.core.data.routes, {
      hideGameInfo: () => {
        svs.components.casino.shared.hideGameInfo();
      },
      showGameInfo: routeData => {
        svs.components.casino.shared.showGameInfo(routeData[1]);
      }
    });
    document.addEventListener('click', e => {
      e.target.classList.contains('js-casino-quick-info-close') && svs.components.casino.shared.hideQuickInfo();
    });
  }
}
setGlobal('svs.components.casino.Casino', new Casino());

 })(window);