(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/casino-promo-actions/views/betdetails.js') >= 0) return;  svs.modules.push('/components/banner_actions/casino-promo-actions/views/betdetails.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_actions=_svs.banner_actions||{};
_svs.banner_actions.casino_promo_actions=_svs.banner_actions.casino_promo_actions||{};
_svs.banner_actions.casino_promo_actions.templates=_svs.banner_actions.casino_promo_actions.templates||{};
svs.banner_actions.casino_promo_actions.templates.betdetails = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":14,"column":15}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"closed") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"suffix":" kr","infoText":(lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"betLimits") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"min") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":4,"column":83},"end":{"line":4,"column":115}}}),"title":"Insats"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"liveCategory") : depth0),"===","blackjack",{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":10,"column":18}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"suffix":" kr","infoText":(lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"betLimits") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"min") : stack1),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":7,"column":85},"end":{"line":7,"column":117}}}),"title":"Insats"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"infoText":(lookupProperty(helpers,"getMoneyRange")||(depth0 && lookupProperty(depth0,"getMoneyRange"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"betLimits") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"min") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"betLimits") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"max") : stack1),{"name":"getMoneyRange","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":132}}}),"title":"Insats"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-casino_info_tag-casino_info_tag"),depth0,{"name":"components-casino-casino_info_tag-casino_info_tag","hash":{"infoText":(lookupProperty(helpers,"getMoneyRange")||(depth0 && lookupProperty(depth0,"getMoneyRange"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"betLimits") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"min") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"betLimits") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"max") : stack1),{"name":"getMoneyRange","hash":{},"data":data,"loc":{"start":{"line":13,"column":81},"end":{"line":13,"column":128}}}),"title":"Insats"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "  &nbsp;\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"betLimits") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"min") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_actions-casino_promo_actions-betdetails'] = svs.banner_actions.casino_promo_actions.templates.betdetails;
})(svs, Handlebars);


 })(window);