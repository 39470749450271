(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/views/promo-hover.js') >= 0) return;  svs.modules.push('/components/casino/game/views/promo-hover.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.game=_svs.casino.game||{};
_svs.casino.game.templates=_svs.casino.game.templates||{};
svs.casino.game.templates.promo_hover = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"casino-promo-hover casino-promo-hover-pnp f-content-inverted align-center f-content\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"casino-promo-hover casino-promo-hover-lb f-content-inverted align-center f-content\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tall-promo-image\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lobbySvgImage") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"lobbySvgImage") : depth0),{"name":"components-content-image_v2-image","hash":{"cssSelectors":"casino-promo-image-wrap"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"lobbyImage") : depth0),{"name":"components-content-image_v2-image","hash":{"cssSelectors":"casino-promo-image-wrap"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"casino-quick-info-prop\">&#x2022;</li>\n        <li class=\"casino-quick-info-prop\">RTP: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"rtp") || (depth0 != null ? lookupProperty(depth0,"rtp") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"rtp","hash":{},"data":data,"loc":{"start":{"line":22,"column":48},"end":{"line":22,"column":55}}}) : helper)))
    + "%</li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"casino-quick-info-prop\">&#x2022;</li>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-game-promo_hover_seats"),depth0,{"name":"components-casino-game-promo_hover_seats","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCluster") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "          <li class=\"casino-quick-info-prop\">&#x2022;</li>\n          <li class=\"casino-quick-info-prop\">Kluster</li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"winLines") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":35,"column":17}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"casino-quick-info-prop\">&#x2022;</li>\n            <li class=\"casino-quick-info-prop\">Vinstlinjer: "
    + container.escapeExpression((lookupProperty(helpers,"formatCurrency")||(depth0 && lookupProperty(depth0,"formatCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"winLines") : depth0),{"name":"formatCurrency","hash":{},"data":data,"loc":{"start":{"line":34,"column":60},"end":{"line":34,"column":87}}}))
    + " st</li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"margin-bottom-1 js-game-promo-btn-play js-casino-track-click","block":true,"disabled":true,"size":300},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":41,"column":136}}})) != null ? stack1 : "")
    + "\n    <div class=\"casino-quick-info-actions"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"freePlayAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":42,"column":41},"end":{"line":42,"column":117}}})) != null ? stack1 : "")
    + "\">\n      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-casino-game-info","transparent":true,"inverted":true,"disabled":true,"size":300,"title":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"Läs mer om ",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":43,"column":27},"end":{"line":43,"column":52}}})},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":6},"end":{"line":43,"column":150}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"freePlayAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":44,"column":6},"end":{"line":55,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "Spela";
},"23":function(container,depth0,helpers,partials,data) {
    return "";
},"25":function(container,depth0,helpers,partials,data) {
    return " casino-quick-info-actions-no-freeplay";
},"27":function(container,depth0,helpers,partials,data) {
    return "Läs mer";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"favorite-button\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFavorite") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":10},"end":{"line":48,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-casino-track-click js-game-promo-btn-demoplay","transparent":true,"inverted":true,"disabled":true,"size":300,"title":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"Läs mer om ",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":50,"column":29},"end":{"line":50,"column":54}}})},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":50,"column":183}}})) != null ? stack1 : "")
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-game-favorite_button"),depth0,{"name":"components-casino-game-favorite_button","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    return "Provspela";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showFavorite") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-game-favorite_button_big"),depth0,{"name":"components-casino-game-favorite_button_big","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isPnpSite") || (depth0 != null ? lookupProperty(depth0,"isPnpSite") : depth0)) != null ? helper : alias2),(options={"name":"isPnpSite","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":58,"column":4},"end":{"line":62,"column":18}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isPnpSite")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"casino-quick-info-actions"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"freePlayAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":63,"column":41},"end":{"line":63,"column":117}}})) != null ? stack1 : "")
    + "\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isPnpSite") || (depth0 != null ? lookupProperty(depth0,"isPnpSite") : depth0)) != null ? helper : alias2),(options={"name":"isPnpSite","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":64,"column":6},"end":{"line":68,"column":20}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isPnpSite")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"freePlayAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":69,"column":6},"end":{"line":84,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"margin-bottom-1 js-game-promo-btn-play js-casino-track-click","block":true,"size":300,"rel":"nofollow","href":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"/spela/",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":59,"column":26},"end":{"line":59,"column":47}}})},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":6},"end":{"line":59,"column":165}}})) != null ? stack1 : "")
    + "\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"margin-bottom-1 js-game-promo-btn-play js-casino-track-click","block":true,"size":300,"rel":"nofollow","href":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"casinoHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":61,"column":32},"end":{"line":61,"column":57}}}),"/spela/",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":61,"column":26},"end":{"line":61,"column":73}}})},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":6},"end":{"line":61,"column":191}}})) != null ? stack1 : "")
    + "\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-casino-game-info","transparent":true,"inverted":true,"size":300,"title":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"Läs mer om ",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":65,"column":103},"end":{"line":65,"column":128}}}),"rel":"nofollow","href":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"/info/",(depth0 != null ? lookupProperty(depth0,"name") : depth0),(lookupProperty(helpers,"returnUrlConditional")||(depth0 && lookupProperty(depth0,"returnUrlConditional"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0),{"name":"returnUrlConditional","hash":{},"data":data,"loc":{"start":{"line":65,"column":48},"end":{"line":65,"column":80}}}),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":65,"column":28},"end":{"line":65,"column":81}}})},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":65,"column":212}}})) != null ? stack1 : "")
    + "\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-casino-game-info","transparent":true,"inverted":true,"size":300,"title":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"Läs mer om ",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":67,"column":129},"end":{"line":67,"column":154}}}),"rel":"nofollow","href":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"casinoHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":67,"column":34},"end":{"line":67,"column":59}}}),"/info/",(depth0 != null ? lookupProperty(depth0,"name") : depth0),(lookupProperty(helpers,"returnUrlConditional")||(depth0 && lookupProperty(depth0,"returnUrlConditional"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0),{"name":"returnUrlConditional","hash":{},"data":data,"loc":{"start":{"line":67,"column":74},"end":{"line":67,"column":106}}}),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":67,"column":28},"end":{"line":67,"column":107}}})},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":67,"column":238}}})) != null ? stack1 : "")
    + "\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "        <div class=\"favorite-button\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFavorite") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":10},"end":{"line":73,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isPnpSite") || (depth0 != null ? lookupProperty(depth0,"isPnpSite") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isPnpSite","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(49, data, 0),"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":79,"column":22}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isPnpSite")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-casino-track-click js-game-promo-btn-demoplay","transparent":true,"inverted":true,"size":300,"title":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"Läs mer om ",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":76,"column":77},"end":{"line":76,"column":102}}}),"rel":"nofollow","href":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"/provspela/",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":76,"column":30},"end":{"line":76,"column":55}}})},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":10},"end":{"line":76,"column":217}}})) != null ? stack1 : "")
    + "\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-casino-track-click js-game-promo-btn-demoplay","transparent":true,"inverted":true,"size":300,"title":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,"Läs mer om ",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":78,"column":103},"end":{"line":78,"column":128}}}),"rel":"nofollow","href":(lookupProperty(helpers,"join")||(depth0 && lookupProperty(depth0,"join"))||alias2).call(alias1,(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"casinoHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":78,"column":36},"end":{"line":78,"column":61}}}),"/provspela/",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"join","hash":{},"data":data,"loc":{"start":{"line":78,"column":30},"end":{"line":78,"column":81}}})},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":10},"end":{"line":78,"column":243}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isPnpSite") || (depth0 != null ? lookupProperty(depth0,"isPnpSite") : depth0)) != null ? helper : alias2),(options={"name":"isPnpSite","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":14}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isPnpSite")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"format") : depth0),"===","tall",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":14,"column":14}}})) != null ? stack1 : "")
    + "  <div class=\"casino-quick-info-content\">\n    <h2 class=\"h3 casino-quick-info-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":16,"column":43},"end":{"line":16,"column":52}}}) : helper)))
    + "</h2>\n    <div class=\"casino-quick-info-props-wrap\">\n      <ul class=\"casino-quick-info-props\">\n        <li class=\"casino-quick-info-prop\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"provider") || (depth0 != null ? lookupProperty(depth0,"provider") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"provider","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":55}}}) : helper)))
    + "</li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"rtp") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"lobbyType") : depth0),"===","live",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":37,"column":20}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":40,"column":2},"end":{"line":86,"column":9}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['components-casino-game-promo_hover'] = svs.casino.game.templates.promo_hover;
})(svs, Handlebars);


 })(window);