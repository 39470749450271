(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/sub-header/views/sub-header.js') >= 0) return;  svs.modules.push('/components/casino/sub-header/views/sub-header.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.sub_header=_svs.casino.sub_header||{};
_svs.casino.sub_header.templates=_svs.casino.sub_header.templates||{};
svs.casino.sub_header.templates.sub_header = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " info-route-header";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":82}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"returnUrl") || (depth0 != null ? lookupProperty(depth0,"returnUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"returnUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":56}}}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"pnpHome") || (depth0 != null ? lookupProperty(depth0,"pnpHome") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pnpHome","hash":{},"data":data,"loc":{"start":{"line":3,"column":64},"end":{"line":3,"column":75}}}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"baseUrl") || (depth0 != null ? lookupProperty(depth0,"baseUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"baseUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":90},"end":{"line":3,"column":101}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    return " js-casino-game-info-hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-casino-header-container casino-header-container"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInfoRoute") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":106}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"casino-header js-casino-header\">\n    <a href=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPnp") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":108}}})) != null ? stack1 : "")
    + "\"\n      class=\"icon-button casino-header-icon-button left js-casino-game-info"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInfoRoute") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":75},"end":{"line":4,"column":134}}})) != null ? stack1 : "")
    + " fc-white\"\n      title=\"Gå till lobby\">\n      <i class=\"icon-default icon-300 fc-white icon-menu-left icon-no-pointer-events\"></i>\n    </a>\n    <div class=\"casino-header-title-container\">\n      <div class=\"casino-header-static-title\">\n        <h1 class=\"casino-header-title js-casino-header-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":10,"column":63},"end":{"line":10,"column":72}}}) : helper)))
    + "</h1>\n      </div>\n      <div class=\"casino-header-scrolling-title-container\">\n        <div class=\"casino-header-scrolling-title-animation-container\">\n          <h2 class=\"casino-header-scrolling-title casino-header-title\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":21}}}) : helper)))
    + " &mdash;&nbsp;"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":15,"column":35},"end":{"line":15,"column":44}}}) : helper)))
    + " &mdash;&nbsp;\n          </h2>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});
Handlebars.partials['components-casino-sub_header-sub_header'] = svs.casino.sub_header.templates.sub_header;
})(svs, Handlebars);


 })(window);