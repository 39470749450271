(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/assets/javascripts/model.js') >= 0) return;  svs.modules.push('/components/casino/game/assets/javascripts/model.js');

'use strict';

class GameModel extends svs.components.casino.PubSub {
  constructor(game) {
    super();
    _.extend(this, game);
    this.changed = {};
    this.socketSubscriptions = 0;
    this.lastJackpotAmount = this.jackpotAmount || false;
    this.isSubscribingToLiveData = false;
    this.isSubscribingToJackpotData = false;
    this.gameStateOpen = 1;
    this.log = svs.core.log.getLogger('component.casino.game.model');
  }
  set(prop, value) {
    switch (prop) {
      default:
        this._set(prop, value);
        this._flush();
        break;
    }
  }
  _set(prop, value) {
    this.changed[prop] = true;
    this[prop] = value;
  }
  _flush() {
    this.emit('change', this.changed);
    this.changed = {};
  }
  hasSocketData() {
    return this.lobbyType === 'live' || this.jackpotId;
  }
  subscribeToSockets() {
    if (this.hasSocketData()) {
      this.socketSubscriptions++;
      this.lobbyType === 'live' && this.subscribeToLiveData();
      this.jackpotId && this.subscribeToJackpot();
    }
  }
  unSubscribeToSockets() {
    if (this.hasSocketData()) {
      this.socketSubscriptions--;
      if (this.socketSubscriptions === 0) {
        this.unSubscribeToLiveData();
        this.unSubscribeToJackpot();
      }
    }
  }
  onSocketUpdate(data) {
    Object.prototype.hasOwnProperty.call(data, 'game') && this._diffLiveData(data.game);
  }
  onJackpotUpdate(data) {
    if (data.jackpot && data.jackpot.amount && this.jackpotAmount !== data.jackpot.amount) {
      this.lastJackpotAmount = this.jackpotAmount || false;
      this.set('jackpotAmount', data.jackpot.amount);
    }
    if (!this.jackpotAmount) {
      const elEmptyJackpot = document.querySelector("a[data-name=\"".concat(this.name, "\"] .casino-game-promo-badge-holder"));
      elEmptyJackpot.innerHTML = "<div class=\"casino-game-promo-badge\">".concat(this.badge || 'JACKPOTSPEL', "</div>");
    }
  }
  subscribeToLiveData() {
    if (!this.isSubscribingToLiveData) {
      svs.core.socket.subscribe("/casino/1/gameinfo/livedata/".concat(this.liveTableId.toLowerCase(), "?supplier=").concat(this.provider), this.onSocketUpdate, this);
      this.isSubscribingToLiveData = true;
    }
  }
  unSubscribeToLiveData() {
    svs.core.socket.unsubscribe("/casino/1/gameinfo/livedata/".concat(this.liveTableId.toLowerCase(), "?supplier=").concat(this.provider), this.onSocketUpdate);
    this.isSubscribingToLiveData = false;
  }
  subscribeToJackpot() {
    if (!this.isSubscribingToJackpotData) {
      this.jackpotSocketUrl = this.jackpotSocketUrl || "/casino/1/gameinfo/jackpots/".concat(this.jackpotId.toLowerCase(), "?supplier=").concat(this.clientStartType);
      svs.core.socket.subscribe(this.jackpotSocketUrl, this.onJackpotUpdate, this);
      this.isSubscribingToJackpotData = true;
    }
  }
  fetchArticle() {
    const options = {
      method: 'GET',
      path: "/content/2/id/".concat(this.articleId),
      data: {
        routesDomain: 'spela'
      }
    };
    svs.core.jupiter.call(options, data => {
      if (data.result) {
        this.set('article', data.result[0]);
      }
    });
  }
  fetchDescription() {
    const options = {
      method: 'GET',
      path: "/content/2/id/".concat(this.id),
      data: {
        routesDomain: 'spela',
        include: 'result.body'
      }
    };
    svs.core.jupiter.call(options, data => {
      if (data.result && data.result.length && data.result[0].body) {
        this.set('description', data.result[0].body);
      } else {
        this.log.warn("Error fetching description for ".concat(this.title), data.error);
      }
    });
  }
  unSubscribeToJackpot() {
    this.jackpotSocketUrl && svs.core.socket.unsubscribe(this.jackpotSocketUrl, this.onJackpotUpdate);
    this.isSubscribingToJackpotData = false;
  }
  isFull() {
    return Boolean(this.lobbyType === 'live' && this.seatsTaken && this.seats && this.seats === this.seatsTaken.length);
  }
  _diffLiveData(data) {
    data.provider = this.provider;
    data = svs.components.casino.game.interpretLiveData(data);
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (!_.isEqual(data[key], this[key])) {
          this._set(key, data[key]);
        }
      }
    }
    if (Object.keys(this.changed).length) {
      this._flush();
    }
  }
  destroy() {
    this.lobbyType === 'live' && this.unSubscribeToLiveData();
    this.unSubscribeToJackpot();
  }
}

setGlobal('svs.components.casino.game.GameModel', GameModel);

 })(window);