(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/assets/javascripts/info-description.js') >= 0) return;  svs.modules.push('/components/casino/info-page/assets/javascripts/info-description.js');
"use strict";


class InfoDescription {
  constructor(game) {
    this.el = document.getElementsByClassName('js-casino-info-description')[0];
    this.gameModel = game;
    this.template = svs.casino.info_page.templates.info_description;
    if (!this.gameModel.description) {
      this.addListeners();
      new svs.ui.Loader('.js-casino-info-description').show(200);
      this.gameModel.fetchDescription();
    }
  }
  addListeners() {
    this.gameModel.on('change', this, changed => {
      changed.description && this.el.replaceWith(this.template(this.gameModel));
    });
  }
  destroy() {
    this.gameModel.off('change', this);
  }
}
setGlobal('svs.components.casino.InfoDescription', InfoDescription);

 })(window);