(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/no-content/assets/javascripts/no-content.js') >= 0) return;  svs.modules.push('/components/casino/no-content/assets/javascripts/no-content.js');
"use strict";

const retryButton = document.querySelector('.js-retry-load-casino-games');
if (retryButton) {
  retryButton.addEventListener('click', () => {
    window.location.reload();
  });
}

 })(window);