(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/casino-teaser/views/game.js') >= 0) return;  svs.modules.push('/widgets/casino-teaser/views/game.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.casino_teaser=_svs.widget.casino_teaser||{};
_svs.widget.casino_teaser.templates=_svs.widget.casino_teaser.templates||{};
svs.widget.casino_teaser.templates.game = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " col-xs-4 col-md-3";
},"3":function(container,depth0,helpers,partials,data) {
    return " col-xs-6 col-am-4 col-md-4 col-lg-3";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"widget-casino-teaser-game"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"format") : depth0),"===","tall",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":142}}})) != null ? stack1 : "")
    + " padding-xs-right-1 padding-xs-bottom-1 padding-md-right-2 padding-md-bottom-2\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-game-promo"),depth0,{"name":"components-casino-game-promo","hash":{"showOrder":(depth0 != null ? lookupProperty(depth0,"showOrder") : depth0),"format":(depth0 != null ? lookupProperty(depth0,"format") : depth0),"index":(depth0 != null ? lookupProperty(depth0,"index") : depth0),"area":(depth0 != null ? lookupProperty(depth0,"area") : depth0),"showFavorite":(depth0 != null ? lookupProperty(depth0,"showFavorite") : depth0),"game":(depth0 != null ? lookupProperty(depth0,"game") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</li>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['widget-casino_teaser-game'] = svs.widget.casino_teaser.templates.game;
})(svs, Handlebars);


 })(window);