(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/assets/javascripts/cache.js') >= 0) return;  svs.modules.push('/components/casino/game/assets/javascripts/cache.js');

'use strict';

var _svs$components;
if (!((_svs$components = svs.components) !== null && _svs$components !== void 0 && (_svs$components = _svs$components.casino) !== null && _svs$components !== void 0 && _svs$components.games)) {
  setGlobal('svs.components.casino.games', {});
}
function extendCache(gameList) {
  let forceUpdate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  gameList && gameList.forEach(game => {
    if (game && !svs.components.casino.games[game.name] || forceUpdate) {
      svs.components.casino.games[game.name] = new svs.components.casino.game.GameModel(game);
    }
  });
}

setGlobal('svs.components.casino.game.extendCache', extendCache);

 })(window);