(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/views/favorite-button.js') >= 0) return;  svs.modules.push('/components/casino/game/views/favorite-button.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.game=_svs.casino.game||{};
_svs.casino.game.templates=_svs.casino.game.templates||{};
svs.casino.game.templates.favorite_button = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " casino-game-is-favorite";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button tabindex=\"0\"\nclass=\"icon-button casino-game-favorite-button js-casino-game-favorite-button"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"favorite") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":77},"end":{"line":2,"column":124}}})) != null ? stack1 : "")
    + "\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":137},"end":{"line":2,"column":145}}}) : helper)))
    + "\">\n  <i class=\"icon-default icon-300 icon-favorites-filled\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":68},"end":{"line":3,"column":76}}}) : helper)))
    + "\"></i>\n  <i class=\"icon-default icon-300 icon-favorites\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":61},"end":{"line":4,"column":69}}}) : helper)))
    + "\"></i>\n</button>\n";
},"useData":true});
Handlebars.partials['components-casino-game-favorite_button'] = svs.casino.game.templates.favorite_button;
})(svs, Handlebars);


 })(window);