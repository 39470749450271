(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/personal-games/assets/javascripts/personal-games.js') >= 0) return;  svs.modules.push('/components/casino/personal-games/assets/javascripts/personal-games.js');

'use strict';

class PersonalGames {
  constructor() {
    if (this.isLoggedIn()) {
      this.el = document.querySelector('#casino-personal-games');
      this.log = new svs.core.log.Logger('components:casino:personal-games');
      this.favoritesPromoViews = svs.components.casino.data.personalGames.favoriteGames.map(game => game.name);
      this.lastPlayedPromoViews = svs.components.casino.data.personalGames.lastPlayedGames.map(game => game.name);
      this.leftIcon = this.el.querySelector('.js-casino-personal-games-button-left');
      this.rightIcon = this.el.querySelector('.js-casino-personal-games-button-right');
      if (this.favoritesPromoViews.length > 0 || this.lastPlayedPromoViews.length > 0) {
        var _this$lastPlayedPromo;
        this.placeholderTemplate = svs.casino.placeholder.templates.placeholder;
        this.placeholder = null;
        this.showLastPlayed = true;
        this.selectedGamesList = [];
        this.scrollAmount = svs.core.detect.breakpoint.greaterThan('md') ? 4 : 3;
        this.activeList = 'last-played';
        const sliderSelector = '.js-slider';
        this.activeListLength = (_this$lastPlayedPromo = this.lastPlayedPromoViews) === null || _this$lastPlayedPromo === void 0 ? void 0 : _this$lastPlayedPromo.length;
        if (document.querySelector(sliderSelector)) {
          if (svs.core.detect.formfactor.mobile()) {
            this.slider = new svs.components.Slider(sliderSelector, {
              scrollSnap: true,
              slideOnDrag: true
            });
          } else {
            this.slider = new svs.components.Slider(sliderSelector, {
              scrollSnap: true,
              slideOnDrag: false
            });
            this.toggleIndicatorVisibility();
          }
        }
        this.changeSliderArrowsState();
        this.createObserver(); 
        this.useGameCache();
        this.initGamePromos();
        this.addListeners();
        this.handlePlaceholders();
      }
    }
  }
  useGameCache() {
    this.favoritesPromoViews && svs.components.casino.game.extendCache(this.favoritesPromoViews.map(gameName => svs.components.casino.data.gameCache.index[gameName]));
    this.lastPlayedPromoViews && svs.components.casino.game.extendCache(this.lastPlayedPromoViews.map(gameName => svs.components.casino.data.gameCache.index[gameName]));
  }

  isAtEndOfList() {
    if (this.activeListLength <= this.slider.getCurrentSlideIndex() + this.scrollAmount) {
      this.rightIcon.disabled = true;
      this.rightIcon.style.cursor = 'default';
    } else {
      this.rightIcon.disabled = false;
      this.rightIcon.style.cursor = 'pointer';
    }
  }

  isAtStartOfList() {
    if (this.slider.getCurrentSlideIndex() === 0 || this.activeListLength <= this.scrollAmount) {
      this.leftIcon.disabled = true;
      this.leftIcon.style.cursor = 'default';
    } else {
      this.leftIcon.disabled = false;
      this.leftIcon.style.cursor = 'pointer';
    }
  }
  toggleList(activeList, promoViewList) {
    if (this.activeList === activeList) {
      return;
    }
    const activeListBtn = this.el.querySelector(".js-pnp-personal-games-".concat(activeList));
    activeListBtn.classList.remove('btn-transparent-default');
    activeListBtn.classList.add('btn-default');
    const oldListBtn = this.el.querySelector(".js-pnp-personal-games-".concat(this.activeList));
    oldListBtn.classList.add('btn-transparent-default');
    oldListBtn.classList.remove('btn-default');
    this.activeList = activeList;
    this.removeAllGames();
    if (this.isLoggedIn()) {
      promoViewList.forEach(game => {
        !this.selectedGamesList[game] && this.insertGame(game, true);
      });
      setTimeout(() => {
        this.slider.resize();
      }, 0);
    }
    this.handlePlaceholders();
  }
  addListeners() {
    var _this$el;
    svs.components.casino.favorites.on('change', this, this.onFavoriteUpdate);
    this.onElClick = this.personalGamesEventHandler.bind(this);
    (_this$el = this.el) === null || _this$el === void 0 || _this$el.addEventListener('click', this.onElClick);
  }

  changeSliderArrowsState() {
    if (this.leftIcon && this.rightIcon) {
      this.isAtStartOfList();
      this.isAtEndOfList();
    }
  }

  toggleBetweenPersonalGamesList(target) {
    if (target.classList.contains('js-pnp-personal-games-favorites')) {
      this.showLastPlayed = false;
      this.activeListLength = this.favoritesPromoViews.length;
      this.toggleList('favorites', this.favoritesPromoViews);
    } else if (target.classList.contains('js-pnp-personal-games-last-played')) {
      this.showLastPlayed = true;
      this.activeListLength = this.lastPlayedPromoViews.length;
      this.toggleList('last-played', this.lastPlayedPromoViews);
    }
    this.changeSliderArrowsState();
  }

  scrollingSliderArrows(target) {
    if (svs.core.detect.formfactor.desktop()) {
      if (target.classList.contains('js-casino-personal-games-menu-left')) {
        this.slider.scrollTo(this.slider.getCurrentSlideIndex() - this.scrollAmount);
      }

      if (target.classList.contains('js-casino-personal-games-menu-right')) {
        this.slider.scrollTo(this.slider.getCurrentSlideIndex() + this.scrollAmount);
      }

      this.changeSliderArrowsState();
    }
  }
  personalGamesEventHandler(e) {
    this.toggleBetweenPersonalGamesList(e.target);
    this.scrollingSliderArrows(e.target);
  }
  async onFavoriteUpdate() {
    this.favoritesPromoViews = await svs.components.casino.favorites.get();
    if (!this.showLastPlayed) {
      this.favoritesPromoViews.forEach(favorite => {
        !this.selectedGamesList[favorite] && this.insertGame(favorite);
      });
      Object.keys(this.selectedGamesList).forEach(game => {
        !this.favoritesPromoViews.includes(game) && this.removeGame(game);
      });
      this.activeListLength = this.favoritesPromoViews.length;
      this.changeSliderArrowsState();
      this.slider.resize();
      this.handlePlaceholders();
    } else {
      this.activeListLength = this.lastPlayedPromoViews.length;
      this.changeSliderArrowsState();
    }
  }
  handlePlaceholders() {
    if (this.isEmpty()) {
      this.createPlaceholder(this.showLastPlayed ? 'last-played' : 'favorites', '', this.showLastPlayed ? 'Här ser du dina senast spelade spel.' : 'Du har inte några favoritmarkerade spel');
      return;
    }
    this.removePlaceholder();
  }
  insertGame(gameName, firstRender) {
    if (gameName) {
      const gameModel = svs.components.casino.games[gameName];
      const model = {
        showFavorite: true,
        game: gameModel || {
          disabled: true,
          title: gameName,
          name: gameName,
          favorite: true,
          format: 'standard'
        }
      };
      const el = new DOMParser().parseFromString(svs.casino.personal_games.templates.game(model), 'text/html').body.firstElementChild;
      if (gameModel) {
        const position = this.el.querySelectorAll('.js-casino-game-promo').length + 1;
        this.selectedGamesList[gameName] = new svs.components.casino.game.PromoView(gameModel, {
          observer: this.observer,
          initPosition: position,
          area: 'personal-games',
          format: 'standard',
          showFavorite: true
        }, el.querySelector('.js-casino-game-promo'));
      } else {
        this.selectedGamesList[gameName] = new svs.components.casino.game.Placeholder({
          observer: this.observer,
          area: 'personal-games',
          format: 'standard',
          showFavorite: true,
          name: gameName,
          favorite: true
        }, el.querySelector('.js-casino-game-promo'));
      }
      const sliderWrapperEl = this.el.querySelector('.js-slider-wrapper');
      if (firstRender) {
        sliderWrapperEl.appendChild(el);
      } else {
        sliderWrapperEl.prepend(el);
      }
    }
  }
  initGamePromos() {
    var _this$el2;
    (_this$el2 = this.el) === null || _this$el2 === void 0 || _this$el2.querySelectorAll('.js-casino-game-promo').forEach((el, i) => {
      const gameName = el.dataset.name;
      const gameModel = svs.components.casino.games[gameName];
      if (gameModel) {
        this.selectedGamesList[gameName] = new svs.components.casino.game.PromoView(gameModel, {
          observer: this.observer,
          initPosition: i,
          area: 'personal-games',
          format: 'standard',
          showFavorite: true
        }, el);
      }
    });
  }
  createPlaceholder(icon, title, information) {
    if (this.placeholder) {
      this.removePlaceholder();
    }
    if (!this.placeholder) {
      var _this$el3;
      this.placeholder = new DOMParser().parseFromString(this.placeholderTemplate({
        icon,
        iconSize: '400',
        title,
        information
      }), 'text/html').body.firstElementChild;
      (_this$el3 = this.el) === null || _this$el3 === void 0 || _this$el3.querySelector('.js-slider-wrapper').appendChild(this.placeholder);
    }
  }
  createObserver() {
    if (window.IntersectionObserver) {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(e => {
          const gamePromoView = $(e.target).data('gamePromoView');
          if (e.isIntersecting === true || e.intersectionRatio > 0) {
            gamePromoView && gamePromoView.visible();
          } else {
            gamePromoView && gamePromoView.hidden();
          }
        });
      }, {
        rootMargin: '0px 0px 500px 0px'
      });
    }
  }
  removeGame(gameName) {
    const gamePromoView = this.selectedGamesList[gameName];
    const el = gamePromoView.el.closest('.js-casino-personal-games-list-item');
    gamePromoView.destroy && gamePromoView.destroy();
    delete this.selectedGamesList[gameName];
    el.remove();
  }
  removeAllGames() {
    for (const gameName of Object.keys(this.selectedGamesList)) {
      this.removeGame(gameName);
    }
  }
  removeListeners() {
    svs.components.casino.favorites.off('change', this);
    if (svs.core.detect.formfactor.desktop()) {
      var _this$el4;
      (_this$el4 = this.el) === null || _this$el4 === void 0 || _this$el4.removeEventListener('click', this.onElClick);
    }
  }
  removePlaceholder() {
    if (this.placeholder) {
      this.placeholder.remove();
      this.placeholder = null;
    }
  }
  isEmpty() {
    return !Object.keys(this.selectedGamesList).length;
  }
  isLoggedIn() {
    return svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  }
  toggleIndicatorVisibility() {
    if (this.isEmpty() && !this.isLoggedIn() && svs.core.detect.formfactor.desktop()) {
      this.el.querySelector('.js-casino-personal-games-navigation-indicators').classList.add('hide');
    }
  }
  destroy() {
    this.removeAllGames();
    this.removeListeners();
  }
}
setGlobal('svs.components.casino.PersonalGames', PersonalGames);

 })(window);