(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/views/info-play-buttons.js') >= 0) return;  svs.modules.push('/components/casino/info-page/views/info-play-buttons.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.info_page=_svs.casino.info_page||{};
_svs.casino.info_page.templates=_svs.casino.info_page.templates||{};
svs.casino.info_page.templates.info_play_buttons = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div itemprop=\"offers\" itemscope itemtype=\"http://schema.org/Offer\" class=\"offer-wrapper\">\n    <a itemprop=\"url\" href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"casinoHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":55}}}))
    + "/provspela/"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":66},"end":{"line":8,"column":74}}}) : helper)))
    + "\" rel=\"nofollow\" class=\"js-casino-info-demoplay btn btn-300 btn-transparent-inverted\n           "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closed") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":11},"end":{"line":9,"column":41}}})) != null ? stack1 : "")
    + "\" title=\"Provspela "
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":60},"end":{"line":9,"column":69}}}) : helper)))
    + "\">\n      <span class=\"preview-btn-link-text\">Provspela</span>\n    </a>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"game-info-actions js-casino-info-play-buttons\" data-game-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":75},"end":{"line":1,"column":83}}}) : helper)))
    + "\"\n  itemprop=\"offers\" itemscope itemtype=\"http://schema.org/AggregateOffer\">\n  <meta itemprop=\"lowPrice\" content=\"0\">\n  <meta itemprop=\"highPrice\" content=\"2000\">\n  <meta itemprop=\"priceCurrency\" content=\"SEK\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"freePlayAllowed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":13,"column":9}}})) != null ? stack1 : "")
    + "  <div itemprop=\"offers\" itemscope itemtype=\"http://schema.org/Offer\" class=\"offer-wrapper\">\n    <a href=\""
    + alias4((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"casinoHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":15,"column":13},"end":{"line":15,"column":40}}}))
    + "/spela/"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":55}}}) : helper)))
    + "\" rel=\"nofollow\"\n      class=\"js-casino-info-play btn btn-300 btn-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closed") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":56},"end":{"line":16,"column":86}}})) != null ? stack1 : "")
    + "\" title=\"Spela "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":16,"column":101},"end":{"line":16,"column":110}}}) : helper)))
    + "\">\n      <span class=\"btn-link-text\">Spela</span>\n    </a>\n  </div>\n</div>\n";
},"useData":true});
Handlebars.partials['components-casino-info_page-info_play_buttons'] = svs.casino.info_page.templates.info_play_buttons;
})(svs, Handlebars);


 })(window);