(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/personal-games/views/personal-games.js') >= 0) return;  svs.modules.push('/components/casino/personal-games/views/personal-games.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.personal_games=_svs.casino.personal_games||{};
_svs.casino.personal_games.templates=_svs.casino.personal_games.templates||{};
svs.casino.personal_games.templates.personal_games = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"personal-games") : depth0)) != null ? lookupProperty(stack1,"isEmpty") : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":34,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "    <div id=\"casino-personal-games\" class=\"casino-personal-games\">\n      <div class=\"casino-personal-games-holder col-xs-12\">\n          <div class=\"casino-personal-games-header grid-row padding-xs-left-1 padding-md-left-2\">\n            <div class=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":75}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button-group",{"name":"ui","hash":{"class":"margin-xs-top-2","block":true},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isDesktop") || (depth0 != null ? lookupProperty(depth0,"isDesktop") : depth0)) != null ? helper : alias2),(options={"name":"isDesktop","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":10},"end":{"line":21,"column":24}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isDesktop")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n        <div class=\"casino-personal-games-games grid-row padding-xs-left-1 padding-md-left-2\">\n          <div class=\"js-slider slider-container col-xs-12 casino-personal-games-list js-casino-personal-games-list\">\n            <ul class=\"col-xs-12 js-slider-wrapper slider-wrapper slider-wrapper-snap casino-personal-games-list-wrapper grid-row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"personal-games") : depth0)) != null ? lookupProperty(stack1,"selectedGamesList") : stack1),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":14},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n          </div>\n        </div>\n      </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"5":function(container,depth0,helpers,partials,data) {
    return "col-xs-4";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"pnp-personal-games-last-played js-pnp-personal-games-last-played","transparent":false,"size":(lookupProperty(helpers,"conditional")||(depth0 && lookupProperty(depth0,"conditional"))||alias2).call(alias1,(lookupProperty(helpers,"isMobile")||(depth0 && lookupProperty(depth0,"isMobile"))||alias2).call(alias1,{"name":"isMobile","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":59}}}),100,300,{"name":"conditional","hash":{},"data":data,"loc":{"start":{"line":8,"column":36},"end":{"line":8,"column":68}}})},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":182}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"pnp-personal-games-favorites js-pnp-personal-games-favorites","transparent":true,"size":(lookupProperty(helpers,"conditional")||(depth0 && lookupProperty(depth0,"conditional"))||alias2).call(alias1,(lookupProperty(helpers,"isMobile")||(depth0 && lookupProperty(depth0,"isMobile"))||alias2).call(alias1,{"name":"isMobile","hash":{},"data":data,"loc":{"start":{"line":9,"column":49},"end":{"line":9,"column":59}}}),100,300,{"name":"conditional","hash":{},"data":data,"loc":{"start":{"line":9,"column":36},"end":{"line":9,"column":68}}})},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":177}}})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "Senast spelade";
},"10":function(container,depth0,helpers,partials,data) {
    return "Mina favoriter";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"col-xs-2 col-xs-offset-6 margin-xs-top-3 js-casino-personal-games-navigation-indicators casino-personal-games-navigation-indicators padding-xs-right-1 padding-md-right-2\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon-button",{"name":"ui","hash":{"class":"js-casino-personal-games-button-left","disabled":true},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon-button",{"name":"ui","hash":{"class":"js-casino-personal-games-button-right","disabled":((stack1 = (depth0 != null ? lookupProperty(depth0,"personal-games") : depth0)) != null ? lookupProperty(stack1,"disableArrow") : stack1)},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"class":"js-casino-personal-games-menu-left casino-personal-games-menu-left","color":"icon-inverted","size":"300","icon":"menu-left"},"data":data,"loc":{"start":{"line":15,"column":14},"end":{"line":15,"column":152}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"class":"js-casino-personal-games-menu-right","color":"icon-inverted","size":"300","icon":"menu-right"},"data":data,"loc":{"start":{"line":18,"column":14},"end":{"line":18,"column":122}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-personal_games-game"),depth0,{"name":"components-casino-personal_games-game","hash":{"area":(depths[1] != null ? lookupProperty(depths[1],"area") : depths[1]),"index":(data && lookupProperty(data,"index")),"game":depth0},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hasRole","hash":{"role":"Player"},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":35,"column":12}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-casino-personal_games-personal_games'] = svs.casino.personal_games.templates.personal_games;
})(svs, Handlebars);


 })(window);