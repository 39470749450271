(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/personal-games/views/game.js') >= 0) return;  svs.modules.push('/components/casino/personal-games/views/game.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.personal_games=_svs.casino.personal_games||{};
_svs.casino.personal_games.templates=_svs.casino.personal_games.templates||{};
svs.casino.personal_games.templates.game = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"casino-personal-games-list-item js-casino-personal-games-list-item js-slider-slide slider-slide col-xs-6 col-am-4 col-md-4 col-lg-3 padding-xs-right-1 padding-xs-bottom-1 padding-md-right-2 padding-md-bottom-2\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-game-promo"),depth0,{"name":"components-casino-game-promo","hash":{"area":(depths[1] != null ? lookupProperty(depths[1],"area") : depths[1]),"index":(data && lookupProperty(data,"index")),"showFavorite":true,"game":(depth0 != null ? lookupProperty(depth0,"game") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </li>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"casino-personal-games-list-item js-casino-personal-games-list-item js-slider-slide slider-slide col-xs-6 col-md-4 padding-xs-right-1 padding-xs-bottom-1 padding-md-right-2 padding-md-bottom-2\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-game-promo"),depth0,{"name":"components-casino-game-promo","hash":{"area":(depths[1] != null ? lookupProperty(depths[1],"area") : depths[1]),"index":(data && lookupProperty(data,"index")),"showFavorite":true,"game":(depth0 != null ? lookupProperty(depth0,"game") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isDesktop") || (depth0 != null ? lookupProperty(depth0,"isDesktop") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isDesktop","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":14}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isDesktop")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-casino-personal_games-game'] = svs.casino.personal_games.templates.game;
})(svs, Handlebars);


 })(window);