(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/ticker/views/winners.js') >= 0) return;  svs.modules.push('/widgets/ticker/views/winners.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.ticker=_svs.widget.ticker||{};
_svs.widget.ticker.templates=_svs.widget.ticker.templates||{};
svs.widget.ticker.templates.winners = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1]),"===","bingo",{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":12,"column":18}}})) != null ? stack1 : "")
    + "        <span class=\"ticker-container__scroller-content__cursor-pointer\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"amountSek") : depth0), depth0))
    + "kr vinst på "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"game") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"ticker-container__letter-badge ticker-bingo-color--"
    + alias2(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":5,"column":73},"end":{"line":5,"column":83}}}) : helper)))
    + "\">\n            "
    + alias2(lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"lettersArr") : depths[1]),(data && lookupProperty(data,"index")),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":43}}}))
    + "\n          </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"ticker-container__ticker-badge ticker-container__badge-icon\">\n            "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"icon":"jackpot","color":"fc-white","size":"100"},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":68}}}))
    + "\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ticker-container__scroller-content__item\">\n    <span>Vinnare!</span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"winners") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});
Handlebars.partials['widget-ticker-winners'] = svs.widget.ticker.templates.winners;
})(svs, Handlebars);


 })(window);