(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/casino/game/assets/javascripts/utils.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
let logger;
let isPnp;
let cmsCDN;
let contentApi;
let trinidad;
if (isServer) {
  trinidad = require('trinidad-core').core;
  logger = trinidad.logger('component:casino:game');
  isPnp = trinidad.config.options.site === 'momang';
  contentApi = trinidad.components.require('content', 'shared').api;
} else {
  var _svs$content;
  logger = svs.core.log.getLogger('component:casino:game');
  cmsCDN = svs.core.config.data.svsconfig.cdn2;
  isPnp = svs.components.casino.data.isPnpSite;
  contentApi = (_svs$content = svs.content) === null || _svs$content === void 0 || (_svs$content = _svs$content.shared) === null || _svs$content === void 0 ? void 0 : _svs$content.cms;
}
const gamePropertiesDefault = ['articleId', 'id', 'name', 'title', 'provider', 'rtp', 'winLines', 'volatility', 'tags', 'badge', 'jackpot', 'jackpotId', 'lobbyImage', 'tallLobbyImage', 'lobbyType', 'lobbySvgImage', 'freePlayAllowed', 'clientStartType', 'liveTableId', 'disclaimer'];
const gamePropertiesLive = ['articleId', 'id', 'name', 'title', 'provider', 'rtp', 'lobbyType', 'tags', 'badge', 'lobbyImage', 'tallLobbyImage', 'lobbySvgImage', 'clientStartType', 'jackpotId', 'liveCategory', 'liveTableId', 'disclaimer'];
function getLiveTableId(game, provider) {
  const operatorsUsingSuppIdAsLiveId = ['playtech', 'netent'];
  if (operatorsUsingSuppIdAsLiveId.includes(provider === null || provider === void 0 ? void 0 : provider.toLowerCase())) {
    return game.operatorSupplementaryId || '';
  }
  return game.providerGameId;
}
function formatImage(_ref) {
  let {
    image,
    loadingLazy = true,
    originalImage = false,
    gameTitle
  } = _ref;
  return _objectSpread(_objectSpread({}, image), {}, {
    fitWrapper: true,
    loadingLazy,
    sizes: '(min-width: 1024px) 20vw, 40vw',
    aspectRatio: {
      height: image.height,
      width: image.width
    },
    altText: image.altText || "Spela ".concat(gameTitle) || '',
    title: image.title || gameTitle || '',
    originalImage
  });
}
function reduceProps(game) {
  const reduceList = game.lobbyType === 'live' ? gamePropertiesLive : gamePropertiesDefault;
  return Object.keys(game).filter(key => reduceList.includes(key)).reduce((obj, key) => {
    obj[key] = game[key];
    return obj;
  }, {});
}

function formatGame(game, reduceProperties) {
  var _game$images;
  if (!game) {
    return undefined;
  }
  const parsedConfig = JSON.parse(game.properties.cnt_casinoGameConfig);
  const volatility = ['Låg', 'Medellåg', 'Medel', 'Medelhög', 'Hög', 'Väldigt hög'];
  const badgeTags = {
    Nyheter: 'Nyhet!',
    Rekommenderat: 'Rekommenderat',
    Jackpotspel: 'Jackpotspel',
    Exklusivt: 'Exklusivt',
    Turnering: 'Turnering!'
  };
  let newGame = {
    id: game.id,
    name: game.name,
    title: game.title,
    description: game.body,
    rank: game.rank,
    lobbyType: parsedConfig.lobbyType,
    category: game.properties.cnt_casinoCategory,
    freePlayAllowed: game.properties.cnt_casinoFreeplayAllowed === true,
    tags: game.properties.cnt_casinoTags,
    provider: game.properties.cnt_casinoProvider,
    rtp: parsedConfig.rtp,
    jackpotId: parsedConfig.jackpotId,
    winLines: parsedConfig.winLines,
    providerGameId: parsedConfig.providerGameId,
    providerGameIdMobile: parsedConfig.providerGameIdMobile,
    opsGameId: parsedConfig.opsGameId,
    opsGameIdMobile: parsedConfig.opsGameIdMobile,
    operatorSupplementaryId: parsedConfig.operatorSupplementaryId,
    clientStartType: parsedConfig.clientStartType,
    clientHeight: parsedConfig.clientHeight,
    clientWidth: parsedConfig.clientWidth,
    clientAllowedOrientation: parsedConfig.clientAllowedOrientation,
    seoDescription: parsedConfig.seoDescription,
    seoKeywords: parsedConfig.seoKeywords,
    articleId: (isPnp ? parsedConfig.momangArticleId : parsedConfig.articleId) || false,
    liveCategory: parsedConfig.liveCategory,
    liveTableId: getLiveTableId(parsedConfig, game.properties.cnt_casinoProvider),
    disclaimer: parsedConfig.disclaimer,
    hasImages: Boolean((_game$images = game.images) === null || _game$images === void 0 ? void 0 : _game$images.length)
  };
  if (parsedConfig.volatility !== undefined) {
    newGame.volatility = volatility[parsedConfig.volatility];
  }
  if (game.properties.cnt_casinoTags) {
    Object.keys(badgeTags).some(tag => {
      if (game.properties.cnt_casinoTags.includes(tag)) {
        newGame.badge = badgeTags[tag];
        return true;
      }
      return false;
    });
  }
  const timedTags = parsedConfig.timedTags || parsedConfig.timedBadges; 
  if (timedTags) {
    const now = Date.now();
    let expiry;
    timedTags.some(tag => {
      expiry = new Date("".concat(tag.expiryDate, "T").concat(tag.expiryTime)).getTime();
      if (now < expiry && tag.badge !== false) {
        if (tag.text || tag.customBadge) {
          newGame.badge = tag.text || tag.customBadge;
        } else {
          newGame.badge = badgeTags[tag.name || tag.badge]; 
        }
        return true;
      }
      return false;
    });
  }
  if (game.images) {
    game.images.forEach(image => {
      if (image.typeName === parsedConfig.lobbyImage.name) {
        newGame.lobbyImage = formatImage({
          image,
          loadingLazy: false,
          originalImage: false,
          gameTitle: game.title
        });
      } else if (parsedConfig.tallLobbyImage && image.typeName === parsedConfig.tallLobbyImage.name) {
        newGame.tallLobbyImage = formatImage({
          image,
          loadingLazy: false,
          originalImage: false,
          gameTitle: game.title
        });
      } else if (parsedConfig.backgroundImage && image.typeName === parsedConfig.backgroundImage.name) {
        newGame.backgroundImage = {
          src: cmsCDN + image.url
        };
      } else if (parsedConfig.lobbySvgImage && image.typeName === parsedConfig.lobbySvgImage.name) {
        newGame.lobbySvgImage = formatImage({
          image,
          loadingLazy: false,
          originalImage: true,
          gameTitle: game.title
        });
      }
    });
  }
  if (reduceProperties) {
    newGame = reduceProps(newGame);
  }
  return newGame;
}
function placeholderGame(gameName) {
  let favorite = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return {
    name: gameName,
    title: gameName.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase()),
    disabled: true,
    favorite
  };
}

function formatGames(cmsGames) {
  let reduceProperties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return cmsGames.map(game => formatGame(game, reduceProperties));
}
async function getGameByCmsId() {
  let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let req = arguments.length > 1 ? arguments[1] : undefined;
  return new Promise((resolve, reject) => {
    contentApi.id({
      req,
      id
    }, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(formatGame(data.result[0]));
      }
    });
  });
}

async function getGameByCmsIdClient() {
  let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return new Promise((resolve, reject) => {
    contentApi.getContentById({
      id
    }, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(formatGame(data.result[0]));
      }
    });
  });
}
async function getArticleByCmsId() {
  let id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let req = arguments.length > 1 ? arguments[1] : undefined;
  return new Promise((resolve, reject) => {
    contentApi.id({
      req,
      id
    }, (err, data) => {
      if (err || !data.result) {
        reject(err);
      } else {
        resolve(data.result[0]);
      }
    });
  });
}

function getAllGames(req) {
  return new Promise((resolve, reject) => {
    const queryFn = contentApi.basicFilter;
    const queryOptions = {
      req,
      type: 'cnt:casinoGame',
      count: 3000,
      offset: 0
    };
    queryFn(queryOptions, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.result || []);
      }
    });
  });
}

async function getLaunchParameters(req, name, demo, urlParams, sessionTime) {
  urlParams = urlParams || {};
  const jupiterOptions = {
    req,
    res: req.res,
    method: 'GET',
    path: "/casino/1/launchparameters?name=".concat(encodeURIComponent(name), "&device=").concat(deviceType(req), "&demo=").concat(demo)
  };
  if (sessionTime !== undefined) {
    jupiterOptions.path += "&sessionTime=".concat(sessionTime);
  }
  Object.keys(urlParams).forEach(param => {
    jupiterOptions.path += "&".concat(param, "URL=").concat(encodeURIComponent(urlParams[param]));
  });
  return await trinidad.jupiter.callAsync(jupiterOptions);
}

function deviceType(req) {
  let device = req.capabilities.formFactor;
  if (device === 'desktop' && (req.capabilities.os === 'iphone os' || req.capabilities.os === 'android')) {
    device = 'tablet';
  } else if (req.capabilities.os === 'os x') {
    const devices = {
      desktop: 'desktop',
      mobile: 'mobile',
      tablet: 'tablet',
      undefined: 'desktop'
    };
    device = devices[req.cookies.casinodevicetype];
  }
  return device;
}
if (isServer) {
  module.exports = {
    getGameByCmsId,
    getArticleByCmsId,
    formatGames,
    formatGame,
    reduceProps,
    placeholderGame,
    getAllGames,
    getLaunchParameters,
    deviceType,
    getLiveTableId
  };
} else {
  svs.components.casino.game = {
    getGameByCmsIdClient,
    getLiveTableId
  };
}

 })(window);