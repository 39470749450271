(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/pnp-casino/assets/javascripts/pnp-info-page.js') >= 0) return;  svs.modules.push('/modules/pnp-casino/assets/javascripts/pnp-info-page.js');
"use strict";


class PnpInfoPage {
  constructor() {
    var _svs$pnp_casino$data$;
    new svs.components.casino.InfoPage(document.querySelector('#casino-game-info-container'), (_svs$pnp_casino$data$ = svs.pnp_casino.data.renderModel) === null || _svs$pnp_casino$data$ === void 0 ? void 0 : _svs$pnp_casino$data$.currentGame);
  }
}
setGlobal('svs.pnp_casino.initPnpInfoPage', () => new PnpInfoPage());

 })(window);