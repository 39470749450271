(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/pnp-casino/views/pnp-lobby-page.js') >= 0) return;  svs.modules.push('/modules/pnp-casino/views/pnp-lobby-page.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.pnp_casino=_svs.pnp_casino||{};
_svs.pnp_casino.templates=_svs.pnp_casino.templates||{};
svs.pnp_casino.templates.pnp_lobby_page = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-accountservices-quick_transaction-transaction_buttons"),depth0,{"name":"components-accountservices-quick_transaction-transaction_buttons","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"lobby-page\" class=\"grid-row lobby-page\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-personal_games-personal_games"),depth0,{"name":"components-casino-personal_games-personal_games","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  "
    + container.escapeExpression((lookupProperty(helpers,"widgetArea")||(depth0 && lookupProperty(depth0,"widgetArea"))||alias2).call(alias1,{"name":"widgetArea","hash":{"name":"lobbyContent"},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":36}}}))
    + "\n</div>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":7,"column":13}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<div class=\"casino-game-info-container\" id=\"casino-game-info-container\"></div>\n<div class=\"casino-quick-info-container\" id=\"casino-quick-info-container\"></div>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['svs-pnp_casino-pnp_lobby_page'] = svs.pnp_casino.templates.pnp_lobby_page;
})(svs, Handlebars);


 })(window);