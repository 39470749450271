(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/casino-promo-actions/assets/javascripts/live-data-view.js') >= 0) return;  svs.modules.push('/components/banner_actions/casino-promo-actions/assets/javascripts/live-data-view.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
class LiveDataView {
  constructor(game, gameModel, gamePromoElement, cmsPreview) {
    this.game = game;
    this.gameModel = gameModel;
    this.isCmsPreview = cmsPreview;
    this.fragments = {
      rouletteNumbers: {
        element: '.js-casinopromo-roulette-numbers',
        template: 'roulette_numbers'
      },
      play: {
        element: '.js-casinopromo-text-btn-play',
        template: 'play'
      },
      betDetails: {
        element: '.js-casinopromo-live-betdetails',
        template: 'betdetails'
      },
      numberOfPlayers: {
        element: '.js-casinopromo-live-number-of-players',
        template: 'number_of_players'
      },
      numberOfSeats: {
        element: '.js-casinopromo-live-number-of-seats',
        template: 'number_of_seats'
      },
      jackpot: {
        element: '.js-casinopromo-badge-holder',
        template: 'badge'
      }
    };
    this.initLiveData(gamePromoElement);
  }
  subscribeToSockets() {
    this.unsubscribeToSockets();
    if (!this.isSubscribed) {
      this.gameModel.subscribeToSockets();
      this.isSubscribed = true;
    }
  }
  unsubscribeToSockets() {
    if (this.isSubscribed) {
      this.gameModel.unSubscribeToSockets();
      this.isSubscribed = false;
    }
  }
  updateFragments(frags) {
    for (const fragmentName of frags) {
      const fragment = this.fragments[fragmentName];
      const isIframe = $('.js-preview-iframe').length && this.isCmsPreview;
      const el = isIframe ? $('.js-preview-iframe').contents().find(fragment.element) : this.element.find(fragment.element);
      el && el.html(svs.banner_actions.casino_promo_actions.templates[fragment.template](_objectSpread(_objectSpread({}, this.gameModel), {}, {
        linkTitle: this.game.playBtnTitle
      })));
    }
  }
  animateJackpot() {
    const value = {
      val: this.gameModel.jackpotAmount
    };
    const fromAmount = this.gameModel.lastJackpotAmount;
    const el = this.element.find(this.fragments.jackpot.element);
    const counter = el.find("#js-jackpot-container-".concat(this.gameModel.name));
    const counterEl = counter[0];
    if (counterEl) {
      gsap.from(value, {
        duration: 0.5,
        ease: 'power2.out',
        val: fromAmount,
        roundProps: 'val',
        onUpdate: () => {
          counterEl.innerText = svs.utils.format.Currency(value.val);
        }
      });
    }
  }
  updateJackpot() {
    this.updateFragments(['jackpot']);
    this.animateJackpot();
  }
  update(changes) {
    Object.keys(changes).forEach(change => {
      switch (change) {
        case 'results':
          this.updateFragments(['rouletteNumbers', 'betDetails']);
          break;
        case 'closed':
          this.updateFragments(['play', 'rouletteNumbers', 'betDetails', 'numberOfPlayers', 'numberOfSeats']);
          break;
        case 'seatsLeft':
          this.updateFragments(['numberOfPlayers', 'numberOfSeats']);
          break;
        case 'seatsTaken':
          this.updateFragments(['numberOfPlayers', 'numberOfSeats', 'rouletteNumbers', 'betDetails']);
          break;
        case 'players':
          this.updateFragments(['numberOfPlayers', 'numberOfSeats', 'rouletteNumbers', 'betDetails']);
          break;
        case 'jackpotAmount':
          this.updateJackpot();
          break;
        default:
          break;
      }
    });
  }
  addElementListeners() {
    this.gameModel.on('change', this, this.update);
  }
  initViews(el) {
    this.element = $(el);
    for (const fragmentName of ['play', 'rouletteNumbers', 'numberOfPlayers', 'numberOfSeats', 'betDetails']) {
      const fragment = this.fragments[fragmentName];
      const isIframe = $('.js-preview-iframe').length && this.isCmsPreview;
      const el = isIframe ? $('.js-preview-iframe').contents().find(fragment.element) : this.element.find(fragment.element);
      el && el.html(svs.banner_actions.casino_promo_actions.templates[fragment.template](_objectSpread(_objectSpread({}, this.gameModel), {}, {
        linkTitle: this.game.playBtnTitle
      })));
    }
  }
  initLiveData(el) {
    if (this.isCmsPreview) {
      setTimeout(() => {
        this.initViews(el);
        this.subscribeToSockets(this.gameModel);
        this.addElementListeners(this.gameModel);
      }, 2000);
    } else {
      this.initViews(el);
      this.subscribeToSockets(this.gameModel);
      this.addElementListeners(this.gameModel);
    }
  }
}
setGlobal('svs.banner_actions.casino_promo_actions', {
  LiveDataView
});

 })(window);