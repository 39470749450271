(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/assets/javascripts/promo-view.js') >= 0) return;  svs.modules.push('/components/casino/game/assets/javascripts/promo-view.js');

'use strict';

class GamePromoView {
  constructor(gameModel, context, gamePromoViewElement) {
    var _svs$components$casin;
    this.context = context || {};
    this.observer = this.context.observer;
    this.gameModel = gameModel;
    this.template = svs.casino.game.templates.promo;
    this.shared = (_svs$components$casin = svs.components.casino) === null || _svs$components$casin === void 0 ? void 0 : _svs$components$casin.shared;
    this.isMobile = svs.core.detect.formfactor.mobile() || svs.core.detect.breakpoint.lessThan('md') || window.matchMedia('(any-hover:none)').matches;
    this.initPosition = (context === null || context === void 0 ? void 0 : context.initPosition) || 0;
    this.area = (context === null || context === void 0 ? void 0 : context.area) || 'Casino';
    this.initElement(gamePromoViewElement);
    this.fragments = {
      liveInfo: {
        element: '.js-casino-game-live-info',
        template: 'promo_live_info'
      },
      seats: {
        element: '.js-casino-game-live-seats',
        template: 'promo_hover_seats_icons'
      },
      jackpot: {
        element: '.js-casino-game-promo-badge-holder',
        template: 'promo_badge'
      }
    };
  }
  subscribeToSockets() {
    if (!this.isSubscribed) {
      this.gameModel.subscribeToSockets();
      this.isSubscribed = true;
    }
  }
  unsubscribeToSockets() {
    if (this.isSubscribed) {
      this.gameModel.unSubscribeToSockets();
      this.isSubscribed = false;
    }
  }
  renderFull() {
    const el = $(this.template({
      game: this.gameModel,
      index: this.initPosition,
      area: this.context.area,
      format: this.context.format,
      showOrder: this.context.showOrder,
      showFavorite: this.context.showFavorite
    }));
    this.observer.unobserve(this.el[0]);
    this.el.replaceWith(el);
    this.initElement(el);
  }
  getPromoData() {
    const promoData = {
      name: this.gameModel.name,
      title: this.el[0].dataset.track,
      position: this.el[0].dataset.areaName
    };
    return promoData;
  }
  scrollText() {
    const propsEl = this.el[0].querySelector('.casino-quick-info-props');
    const container = this.el[0].querySelector('.casino-game-promo-hover-play-container');
    this.onMouseEnter = () => {
      const shouldScrollText = (propsEl === null || propsEl === void 0 ? void 0 : propsEl.clientWidth) < (propsEl === null || propsEl === void 0 ? void 0 : propsEl.scrollWidth);
      if (shouldScrollText) {
        propsEl === null || propsEl === void 0 || propsEl.classList.add('casino-quick-info-props-scroll-text');
        container === null || container === void 0 || container.classList.add('promo-hover-game-title-container-blur');
      }
    };
    this.onMouseLeave = () => {
      propsEl === null || propsEl === void 0 || propsEl.classList.remove('casino-quick-info-props-scroll-text');
      container === null || container === void 0 || container.classList.add('promo-hover-game-title-container-blur');
    };
    this.el[0].addEventListener('mouseenter', this.onMouseEnter);
    this.el[0].addEventListener('mouseleave', this.onMouseLeave);
  }
  async initElement(gamePromoViewElement) {
    this.el = $(gamePromoViewElement);
    this.el.data('gamePromoView', this);
    this.observer.observe(this.el[0]);
    this.fullyRendered = !this.observer || this.el.hasClass('js-casino-game-promo-fully-rendered');
    if (this.fullyRendered) {
      svs.core.detect.formfactor.desktop() && this.scrollText();
      this.addElementListeners();
      if (svs.casino.navigate) {
        svs.casino.navigate.initNavigation(this.el);
      }
    }
    this.toggleFavorite(await svs.components.casino.favorites.isFavorite(this.gameModel.name));
  }
  addElementListeners() {
    this.el.on('click', '.js-casino-game-favorite-button', async () => {
      if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
        svs.utils.url.navigate(svs.core.urlMapping.get('loginHome'));
        return;
      }
      svs.components.casino.favorites.set(this.gameModel.name, !(await svs.components.casino.favorites.isFavorite(this.gameModel.name)));
    });
    this.el.on('click', '.js-casino-game-info', e => {
      var _this$getPromoData;
      e.preventDefault();
      const gameName = (_this$getPromoData = this.getPromoData()) === null || _this$getPromoData === void 0 ? void 0 : _this$getPromoData.name;
      if (this.isMobile) {
        if (svs.components.casino.shared || this.el.hasClass('js-casino-game-promo-presentation')) {
          svs.components.casino.shared.showQuickInfo(gameName, e);
        } else {
          svs.utils.url.navigate("/info/".concat(encodeURIComponent(gameName)));
        }
      } else {
        if (svs.components.casino.router) {
          var _document;
          const revealElement = (_document = document) === null || _document === void 0 ? void 0 : _document.querySelector('.js-reveal-animation');
          if (revealElement) {
            revealElement.classList.add('reveal-animation-casino-blur');
          }
          svs.components.revealAnimation.runRevealAnimation(e, () => {
            svs.components.casino.router.navigate("".concat(svs.core.data.baseOriginalUrl, "info/").concat(encodeURIComponent(gameName)), false, null, {
              title: "Spela ".concat(this.gameModel.title, " Online | Momang Casino | Svenska Spel"),
              description: "".concat(this.gameModel.description)
            });
          });
        } else {
          svs.utils.url.navigate("/info/".concat(encodeURIComponent(gameName)));
        }
      }
    });
    this.el.on('click', '.js-game-promo-btn-play', e => {
      e.preventDefault();
      e.stopPropagation();
      this.shared.startGame('spela', this.gameModel.name, this.getPromoData());
    });
    this.el.on('click', '.js-game-promo-btn-demoplay', e => {
      e.preventDefault();
      e.stopPropagation();
      this.shared.startGame('provspela', this.gameModel.name, this.getPromoData());
    });
  }
  refreshFragments(fragments) {
    for (const fragmentName of fragments) {
      const fragment = this.fragments[fragmentName];
      const el = this.el.find(fragment.element);
      el && el.html(svs.casino.game.templates[fragment.template](this.gameModel));
    }
  }
  animateJackpot() {
    const value = {
      val: this.gameModel.jackpotAmount
    };
    const fromAmount = this.gameModel.lastJackpotAmount;
    const el = this.el.find(this.fragments.jackpot.element);
    const counter = el.find("#js-jackpot-container-".concat(this.gameModel.name));
    const counterEl = counter[0];
    if (counterEl) {
      gsap.from(value, {
        duration: 0.5,
        ease: 'power2.out',
        val: fromAmount,
        roundProps: 'val',
        onUpdate: () => {
          counterEl.innerText = svs.utils.format.Currency(value.val);
        }
      });
    }
  }
  refreshJackpot() {
    this.refreshFragments(['jackpot']);
    this.animateJackpot();
  }
  toggleFavorite(state) {
    const favoriteButton = this.el.find('.js-casino-game-favorite-button');
    const titleName = state ? 'Avmarkera som favorit' : 'Lägg till som favorit';
    favoriteButton.toggleClass('casino-game-is-favorite', state);
    favoriteButton.attr('title', titleName);
  }
  refresh(changes) {
    Object.keys(changes).forEach(change => {
      switch (change) {
        case 'results':
          this.refreshFragments(['liveInfo']);
          break;
        case 'closed':
          this.refreshFragments(['liveInfo', 'seats']);
          break;
        case 'seatsLeft':
          this.refreshFragments(['seats']);
          break;
        case 'seatsTaken':
          this.refreshFragments(['seats', 'liveInfo']);
          break;
        case 'players':
          this.refreshFragments(['seats', 'liveInfo']);
          break;
        case 'jackpotAmount':
          this.refreshJackpot();
          break;
        default:
          break;
      }
    });
  }
  visible() {
    if (!this.fullyRendered) {
      this.renderFull();
    }
    this.gameModel.on('change', this, this.refresh);
    svs.components.casino.favorites.on("change-".concat(this.gameModel.name), this, this.toggleFavorite);
    this.observer && this.subscribeToSockets();
  }
  hidden() {
    this.gameModel.off('change', this, this.refresh);
    svs.components.casino.favorites.off("change-".concat(this.gameModel.name), this, this.toggleFavorite);
    this.observer && this.unsubscribeToSockets();
  }
  destroy() {
    this.unsubscribeToSockets();
    svs.components.casino.favorites.off("change-".concat(this.gameModel.name), this, this.toggleFavorite);
    if (this.gameModel) {
      this.gameModel.off('change', this);
    }
    this.gameModel = null;
    this.template = null;
    if (this.observer) {
      this.observer.unobserve(this.el[0]);
    }
    this.observer = null;
    this.onMouseEnter && this.el[0].removeEventListener('mouseenter', this.onMouseEnter);
    this.onMouseLeave && this.el[0].removeEventListener('mouseleave', this.onMouseLeave);
  }
}

setGlobal('svs.components.casino.game.PromoView', GamePromoView);

 })(window);