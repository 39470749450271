(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/quick-info/assets/javascripts/quick-info.js') >= 0) return;  svs.modules.push('/components/casino/quick-info/assets/javascripts/quick-info.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


class QuickInfo {
  constructor(el, game) {
    this.el = el;
    this.game = game;
    this.gameName = this.game.name || this.game;
    this.template = svs.casino.quick_info.templates.quick_info;
    this.shared = svs.casino && svs.components.casino.shared || svs.casino && svs.components.casino.shared;
    this.isOpen = false;
    const model = {
      showFavorite: true,
      game: typeof this.game === 'object' ? this.game : {
        disabled: true,
        title: this.game,
        name: this.game,
        favorite: true,
        format: 'standard'
      }
    };
    const node = new DOMParser().parseFromString(this.template(model), 'text/html').body.firstElementChild;
    this.el.appendChild(node);
    this.gameNode = node.querySelector('.js-casino-game-info');
    this.gameNode.addEventListener('click', e => this.showGameInfo(e));
    this.addAnimations();
    this.mouseUpFunc = this.hideOnClickOutside.bind(this);
    document.addEventListener('mouseup', this.mouseUpFunc);
    this.favoriteBtn = this.el.querySelector('.js-casino-game-favorite-button');
    this.playButton = this.el.querySelector('.js-game-promo-btn-play');
    this.demoPlayButton = this.el.querySelector('.js-game-promo-btn-demoplay');
    this.favoriteClickFunc = this.onFavorite.bind(this);
    this.favoriteBtn.addEventListener('click', this.favoriteClickFunc);
    this.playButton.addEventListener('click', this.handlePlayClick.bind(this));
    this.demoPlayButton && this.demoPlayButton.addEventListener('click', this.handleDemoPlayClick.bind(this));
    svs.components.casino.favorites.on("change-".concat(this.gameName), this, this.toggleFavorite);
    this.initFavorite();
    if (this.game.lobbyType === 'live') {
      this.game.on('change', this, this.onSocketUpdate);
      this.game.subscribeToSockets();
      this.isSubscribed = true;
      this.seatsEl = this.el.querySelector('.js-casino-game-live-seats');
      this.seatsTemplate = svs.casino.game.templates.promo_hover_seats;
    }
  }
  async initFavorite() {
    this.toggleFavorite(await svs.components.casino.favorites.isFavorite(this.gameName));
  }
  async onFavorite() {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      svs.utils.url.navigate(svs.core.urlMapping.get('loginHome'));
      return;
    }
    svs.components.casino.favorites.set(this.gameName, !(await svs.components.casino.favorites.isFavorite(this.gameName)));
  }
  getPromoData(game) {
    var _document$querySelect;
    const gameData = (_document$querySelect = document.querySelector("div.js-casino-game-promo[data-name=\"".concat(game.name, "\"]"))) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.dataset;
    const {
      name,
      areaName,
      track
    } = gameData;
    return {
      name,
      title: track,
      position: areaName || 'no-origin'
    };
  }
  handlePlayClick(e) {
    e.preventDefault();
    e.stopPropagation();
    svs.components.casino.shared.startGame('spela', this.game.name, this.getPromoData(this.game));
  }
  handleDemoPlayClick(e) {
    e.preventDefault();
    e.stopPropagation();
    svs.components.casino.shared.startGame('provspela', this.game.name, this.getPromoData(this.game));
  }
  toggleFavorite(isFavorite) {
    const titleName = isFavorite ? 'Avmarkera som favorit' : 'Lägg till som favorit';
    this.favoriteBtn.classList.toggle('casino-game-is-favorite', isFavorite);
    this.favoriteBtn.setAttribute('title', titleName);
  }
  onSocketUpdate(changes) {
    if (changes.seatsLeft || changes.seatsTaken || changes.players) {
      const game = this.game;
      const node = new DOMParser().parseFromString(this.seatsTemplate(_objectSpread({}, game)), 'text/html').body.firstElementChild;
      this.seatsEl.innerHTML = node.innerHTML;
    }
  }
  showGameInfo(e) {
    var _document;
    e.preventDefault();
    const revealElement = (_document = document) === null || _document === void 0 ? void 0 : _document.querySelector('.js-reveal-animation');
    if (revealElement) {
      revealElement.classList.add('reveal-animation-casino-blur');
    }
    svs.components.revealAnimation.runRevealAnimation(e, () => {
      svs.components.casino.router.navigate("".concat(svs.core.data.baseOriginalUrl, "info/").concat(encodeURIComponent(this.gameName)), false, null, {
        title: "Spela ".concat(this.game.title, " Online | ").concat(this.shared.isPnp() && 'Momang ', "Casino | Svenska Spel"),
        description: "".concat(this.game.description)
      }, {
        toggleMobileMenu: window.location.pathname !== svs.core.urlMapping.get('pnpSearch')
      });
      this.destroy();
    });
  }
  hideOnClickOutside(e) {
    if (e.target !== this.el && !this.el.contains(e.target)) {
      this.isOpen && this.animateHide();
    }
  }
  animateShow() {
    this.isOpen = true;
    this.tl.restart();
  }
  animateHide() {
    this.tl.reverse();
  }
  addScrollingTxt() {
    const propsEl = this.el.querySelector('.casino-quick-info-props');
    if (propsEl.clientWidth < propsEl.scrollWidth) {
      propsEl.classList.add('casino-quick-info-props-scroll-text');
    }
  }
  addAnimations() {
    this.tl = new gsap.timeline({
      paused: true,
      onStart: () => {
        this.el.classList.add('show-quick-info');
      },
      onReverseComplete: () => {
        this.el.classList.remove('show-quick-info');
        this.destroy();
      }
    });
    this.tl.fromTo(this.el.querySelector('.casino-quick-info'), 0.2, {
      ease: Expo.easeOut,
      yPercent: 100
    }, {
      ease: Expo.easeOut,
      yPercent: 0,
      onStart: () => this.addScrollingTxt()
    });
  }
  destroy() {
    this.el.firstChild && this.el.firstChild.remove();
    this.tl.kill();
    this.tl = null;
    this.isOpen = false;
    this.gameNode.removeEventListener('click', this.showGameInfo);
    document.removeEventListener('mouseup', this.mouseUpFunc);
    this.favoriteBtn.removeEventListener('click', this.favoriteClickFunc);
    this.playButton.removeEventListener('click', this.handlePlayClick);
    this.demoPlayButton && this.demoPlayButton.removeEventListener('click', this.handleDemoPlayClick);
    if (this.isSubscribed) {
      this.game.unSubscribeToSockets();
      this.isSubscribed = false;
      this.game.off('change', this);
    }
    this.game = null;
  }
}
setGlobal('svs.components.casino.QuickInfo', QuickInfo);

 })(window);