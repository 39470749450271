(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/pnp-casino/assets/javascripts/pnp-lobby-page.js') >= 0) return;  svs.modules.push('/modules/pnp-casino/assets/javascripts/pnp-lobby-page.js');
"use strict";


class PnpLobbyPage {
  constructor() {
    var _svs$pnp_casino$data;
    if (svs.core.detect.formfactor.mobile() && !((_svs$pnp_casino$data = svs.pnp_casino.data) !== null && _svs$pnp_casino$data !== void 0 && (_svs$pnp_casino$data = _svs$pnp_casino$data.renderModel) !== null && _svs$pnp_casino$data !== void 0 && _svs$pnp_casino$data.gameCacheEmpty)) {
      new svs.accountservices.quick_transaction.TransactionButtons();
    }
    new svs.components.casino.PersonalGames();
    svs.components.casino.animateHeader.init();
  }
}
setGlobal('svs.pnp_casino.initPnpLobbyPage', () => new PnpLobbyPage());

 })(window);