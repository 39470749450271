(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/assets/javascripts/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/casino/info-page/assets/javascripts/handlebars-helpers.js');


'use strict';

(function () {
  if (svs.isServer) {
    const hbs = require('hbs');
    const urlMapping = require('trinidad-core').core.urlMapping;
    registerHelpers(hbs, urlMapping);
  } else {
    registerHelpers(Handlebars, svs.core.urlMapping);
  }
  function registerHelpers(hbs, urlMapping) {
    hbs.registerHelper('returnUrl', options => {
      var _options$data;
      if (svs.isServer && options !== null && options !== void 0 && (_options$data = options.data) !== null && _options$data !== void 0 && (_options$data = _options$data.root) !== null && _options$data !== void 0 && _options$data.infoPageReturnUrl) {
        return options.data.root.infoPageReturnUrl;
      }
      return '';
    });
    hbs.registerHelper('getCasinoGameTermsDocumentLink', options => {
      var _options$data2, _svs$components;
      const casinoGameTermsId = (options === null || options === void 0 || (_options$data2 = options.data) === null || _options$data2 === void 0 || (_options$data2 = _options$data2.root['info-page']) === null || _options$data2 === void 0 ? void 0 : _options$data2.casinoGameTermsDocumentId) || ((_svs$components = svs.components) === null || _svs$components === void 0 || (_svs$components = _svs$components.casino) === null || _svs$components === void 0 || (_svs$components = _svs$components.data) === null || _svs$components === void 0 ? void 0 : _svs$components.casinoGameTermsDocumentId);
      if (casinoGameTermsId) {
        return "/dokument/".concat(casinoGameTermsId);
      }
      return urlMapping.get('termsIntegrityRules');
    });
  }
})();

 })(window);