(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/assets/javascripts/placeholder.js') >= 0) return;  svs.modules.push('/components/casino/game/assets/javascripts/placeholder.js');

'use strict';

class PlaceholderView {
  constructor(model, gamePromoViewElement) {
    this.model = model;
    this.shared = svs.casino && svs.components.casino.shared || svs.casino && svs.components.casino.shared;
    this.isMobile = svs.core.detect.formfactor.mobile() || svs.core.detect.breakpoint.lessThan('md') || window.matchMedia('(any-hover:none)').matches;
    this.initElement(gamePromoViewElement);
  }
  async initElement(gamePromoViewElement) {
    this.el = $(gamePromoViewElement);
    this.fullyRendered = this.el.hasClass('js-casino-game-promo-fully-rendered');
    if (this.fullyRendered) {
      this.addElementListeners();
    }
    this.toggleFavorite(await svs.components.casino.favorites.isFavorite(this.model.name));
  }
  addElementListeners() {
    svs.components.casino.favorites.on("change-".concat(this.model.name), this, this.toggleFavorite);
    this.el.on('click', '.js-casino-game-info', e => {
      e.preventDefault();
      const gameName = this.model.name;
      if (this.isMobile && svs.components.casino.shared) {
        svs.components.casino.shared.showQuickInfo(gameName, e);
      }
    });
    this.el.on('click', '.js-casino-game-favorite-button', async () => {
      if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
        svs.utils.url.navigate(svs.core.urlMapping.get('loginHome'));
        return;
      }
      svs.components.casino.favorites.set(this.model.name, !(await svs.components.casino.favorites.isFavorite(this.model.name)));
    });
  }
  toggleFavorite(state) {
    const favoriteButton = this.el.find('.js-casino-game-favorite-button');
    const titleName = state ? 'Avmarkera som favorit' : 'Lägg till som favorit';
    favoriteButton.toggleClass('casino-game-is-favorite', state);
    favoriteButton.attr('title', titleName);
  }
  destroy() {
    this.onMouseEnter && this.el[0].removeEventListener('mouseenter', this.onMouseEnter);
    this.onMouseLeave && this.el[0].removeEventListener('mouseleave', this.onMouseLeave);
  }
}

setGlobal('svs.components.casino.game.Placeholder', PlaceholderView);

 })(window);