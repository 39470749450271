(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/casino-promo-actions/assets/javascripts/play-button.js') >= 0) return;  svs.modules.push('/components/banner_actions/casino-promo-actions/assets/javascripts/play-button.js');

'use strict';

class PlayButton {
  constructor(gameModel, el) {
    this.gameModel = gameModel;
    this.el = el;
    this.gameName = this.gameModel.name;
    this.playButton = this.el.querySelector('.js-casinopromo-play-button');
    this.addElementListeners();
  }
  addElementListeners() {
    this.playButton.addEventListener('click', e => {
      e.preventDefault();
      svs.components.casino.shared.startGame('spela', this.gameName, this.getPromoData(), this.gameModel);
    });
  }
  getPromoData() {
    const promoData = {
      name: this.gameName,
      title: this.gameName,
      position: this.el.dataset.impressionTracking
    };
    return promoData;
  }
}
setGlobal('svs.banner_actions.casino_promo_actions', {
  PlayButton
});

 })(window);