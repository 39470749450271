(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/pnp-casino/views/missing-content-page.js') >= 0) return;  svs.modules.push('/modules/pnp-casino/views/missing-content-page.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.pnp_casino=_svs.pnp_casino||{};
_svs.pnp_casino.templates=_svs.pnp_casino.templates||{};
svs.pnp_casino.templates.missing_content_page = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"no-game-page-error-page\" class=\"grid-row\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-placeholder-placeholder"),depth0,{"name":"components-casino-placeholder-placeholder","hash":{"information":(depth0 != null ? lookupProperty(depth0,"information") : depth0),"title":(depth0 != null ? lookupProperty(depth0,"headerTitle") : depth0),"iconSize":1000,"icon":"icecream"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  "
    + container.escapeExpression((lookupProperty(helpers,"widgetArea")||(depth0 && lookupProperty(depth0,"widgetArea"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"widgetArea","hash":{"name":"missingContent"},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":38}}}))
    + "\n</div>\n\n<div class=\"casino-game-info-container\" id=\"casino-game-info-container\"></div>\n<div class=\"casino-quick-info-container\" id=\"casino-quick-info-container\"></div>\n\n";
},"usePartial":true,"useData":true});
Handlebars.partials['svs-pnp_casino-missing_content_page'] = svs.pnp_casino.templates.missing_content_page;
})(svs, Handlebars);


 })(window);