(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/game/assets/javascripts/helpers/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/casino/game/assets/javascripts/helpers/handlebars-helpers.js');


'use strict';

(function () {
  let trinidad;
  if (svs.isServer) {
    const hbs = require('hbs');
    trinidad = require('trinidad-core').core;
    registerHelpers(hbs);
  } else {
    registerHelpers(Handlebars);
  }
  function registerHelpers(hbs) {
    hbs.registerHelper('getRouletteColor', number => ({
      '00': 'green',
      0: 'green',
      1: 'red',
      2: 'black',
      3: 'red',
      4: 'black',
      5: 'red',
      6: 'black',
      7: 'red',
      8: 'black',
      9: 'red',
      10: 'black',
      11: 'black',
      12: 'red',
      13: 'black',
      14: 'red',
      15: 'black',
      16: 'red',
      17: 'black',
      18: 'red',
      19: 'red',
      20: 'black',
      21: 'red',
      22: 'black',
      23: 'red',
      24: 'black',
      25: 'red',
      26: 'black',
      27: 'red',
      28: 'black',
      29: 'black',
      30: 'red',
      31: 'black',
      32: 'red',
      33: 'black',
      34: 'red',
      35: 'black',
      36: 'red'
    })[number]);
    hbs.registerHelper('getSeatsLeft', gameData => {
      switch (gameData.provider) {
        case 'Evolution':
          return gameData.seatsTaken ? gameData.seats - gameData.seatsTaken.length : null;
        case 'Playtech':
          if (gameData.seatsTaken) {
            return gameData.seatsTaken.filter(seat => seat === 1).length;
          }
          return null;
        default:
          return null;
      }
    });
    hbs.registerHelper('hasSeatsLeft', (gameData, options) => {
      if (gameData.provider === 'Playtech' && gameData.seatsTaken && gameData.seats > 0) {
        return options.fn(this);
      } else if (gameData.provider === 'Evolution' && gameData.seatsTaken && gameData.seats && gameData.seats - gameData.seatsTaken.length > 0) {
        return options.fn(this);
      }
      return options.inverse(this);
    });
    hbs.registerHelper('isPnpSite', function (options) {
      if (svs.isServer) {
        if (trinidad.config.options.site === 'momang') {
          return options.fn(this);
        }
      } else if (svs.components.casino.data.isPnpSite) {
        return options.fn(this);
      }
      return options.inverse(this);
    });
    hbs.registerHelper('returnUrlConditional', (returnUrl, options) => {
      var _options$data;
      if (returnUrl) {
        return "?returnUrl=".concat(returnUrl);
      }
      if (svs.isServer && options !== null && options !== void 0 && (_options$data = options.data) !== null && _options$data !== void 0 && (_options$data = _options$data.root) !== null && _options$data !== void 0 && _options$data.returnUrl) {
        var _options$data2;
        return "?returnUrl=".concat(options === null || options === void 0 || (_options$data2 = options.data) === null || _options$data2 === void 0 || (_options$data2 = _options$data2.root) === null || _options$data2 === void 0 ? void 0 : _options$data2.returnUrl);
      }
      return '';
    });

    hbs.registerHelper('multiply', (x, y) => x * y);

    hbs.registerHelper('increase', value => parseInt(value, 10) + 1);
    hbs.registerHelper('hasJackpot', value => value ? 'casino-game-promo-badge-has-jackpot' : 'casino-game-promo-badge-no-jackpot');
  }
})();

 })(window);