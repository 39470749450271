(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/no-content/views/no-content.js') >= 0) return;  svs.modules.push('/components/casino/no-content/views/no-content.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.casino=_svs.casino||{};
_svs.casino.no_content=_svs.casino.no_content||{};
_svs.casino.no_content.templates=_svs.casino.no_content.templates||{};
svs.casino.no_content.templates.no_content = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "Försök igen";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"casino-error-boundary-wrapper f-content align-center\">\n  "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"icon",{"name":"ui","hash":{"size":"1100","color":"fc-red","icon":"icecream"},"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":58}}}))
    + "\n  <h2 class=\"margin-bottom-1\">Något gick snett!</h2>\n  <p class=\"margin-bottom-1 casino-no-games-error-message\">Just nu går det inte att läsa in våra spel. Försök igen om en liten stund.<br><br>\n  "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"retry-load-casino-games js-retry-load-casino-games","inverted":true,"linkTitle":"Försök igen","size":300},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":142}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});
Handlebars.partials['components-casino-no_content-no_content'] = svs.casino.no_content.templates.no_content;
})(svs, Handlebars);


 })(window);