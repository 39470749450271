(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/ticker/assets/javascripts/ticker.js') >= 0) return;  svs.modules.push('/widgets/ticker/assets/javascripts/ticker.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  TickerTypeTypes,
  AnimationTypes
} = svs.widget.ticker.constants;
class Ticker {
  constructor(options) {
    this.options = options;
    this.el = options.$el[0];
    this.scrollerContent = this.el.querySelector('.ticker-container__scroller-content');
    this.isSubscribedToWinners = false;
    this.endpoint = this.options.model.feed;
    this.template = svs.widget.ticker.templates.winners;
    this.previousWinnerFeed = this.options.model.winners;
    this.tickerType = this.options.model.type;
    this.animationType = this.options.model.animationType;
    this.setAnimation = false;
    this.subscribeToWinners();
    this.init();
  }
  startAnimation() {
    const scroller = this.scrollerContent;
    for (let i = 0; i < 2; i++) {
      const clone = scroller.cloneNode(true);
      scroller.parentNode.appendChild(clone);
    }
    scroller.i = 0;
    scroller.step = 0.5;
    scroller.width = scroller.clientWidth + 24;
    scroller.parentNode.addEventListener('mouseenter', () => scroller.step = 0, false);
    scroller.parentNode.addEventListener('mouseleave', () => scroller.step = 0.5, false);
    scroller.addEventListener('mouseenter', () => scroller.step = 0, false);
    scroller.addEventListener('mouseleave', () => scroller.step = 0.5, false);
    function move() {
      scroller.style.marginLeft = "-".concat(scroller.i, "px");
      scroller.i = scroller.i < scroller.width ? scroller.i + scroller.step : 1;
      requestAnimationFrame(move);
    }
    requestAnimationFrame(move);
  }
  setScrollingSpeed() {
    const timePerPixel = 20;
    const nodeList = this.el.children;
    const containerWidth = this.scrollerContent.offsetWidth;
    Array.from(nodeList).forEach(node => {
      const nodeWidth = node.offsetWidth;
      const distance = nodeWidth + containerWidth;
      const duration = timePerPixel * distance;
      node.style.animationDuration = "".concat(duration, "ms");
    });
  }
  onSocketUpdate(data) {
    let winners = data.winnerFeed.items || [];
    winners = winners.slice(0, 5);
    this.scrollerContent.innerHTML = this.template(_objectSpread(_objectSpread({}, this.options.model), {}, {
      winners
    }));
    this.previousWinnerFeed = winners;
  }
  subscribeToWinners() {
    if (!this.isSubscribedToWinners && this.tickerType === TickerTypeTypes.TYPE_WINNER) {
      svs.core.socket.subscribe("/casino/1/winnerfeed/".concat(this.endpoint), this.onSocketUpdate, this);
      this.isSubscribedToWinners = true;
    }
  }
  init() {
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      if (this.animationType === AnimationTypes.TYPE_JAVASCRIPT) {
        this.startAnimation();
      } else {
        this.setScrollingSpeed();
      }
      this.setAnimation = true;
    }
  }
}
setGlobal('svs.widget.ticker.init', options => {
  if (options) {
    new Ticker(options);
  }
});

 })(window);