(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/casino/info-page/assets/javascripts/info-page.js') >= 0) return;  svs.modules.push('/components/casino/info-page/assets/javascripts/info-page.js');
"use strict";


class InfoPage {
  constructor(el, game) {
    let render = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    this.el = el;
    this.game = svs.components.casino.games[game.name] || new svs.components.casino.game.GameModel(game);
    this.template = svs.casino.info_page.templates.info_page;
    this.childNodes = ['InfoDescription', 'InfoDetails', 'InfoPlayButtons', 'InfoImage', 'CmsArticle'];
    if (game && render) {
      const node = new DOMParser().parseFromString(this.template({
        currentGame: this.game
      }), 'text/html').body.firstElementChild;
      this.el.appendChild(node);
    }
    this.runChildScripts();
    const isPnp = svs.components.casino.shared.isPnp();
    const isMobile = svs.core.detect.formfactor.mobile();
    this.navigateBackEl = document.querySelector('.casino-info-page-contentholder .js-casino-game-info-hide');
    this.navigateBackEl && this.navigateBackEl.addEventListener('click', e => this.navigateBackEvent(e));
    if (isPnp && isMobile) {
      svs.components.casino.animateHeader.disable();
    }
  }
  runChildScripts() {
    this.childViews = this.childNodes.map(child => new svs.components.casino[child](this.game));
  }
  navigateBackEvent(e) {
    e.preventDefault();
    if (document.querySelector('#no-game-page-error-page')) {
      svs.utils.url.navigate(svs.core.urlMapping.get('casinoHome'));
    } else {
      svs.components.casino.router.back();
    }
  }
  getPromoData(gamePromoView) {
    const areaName = gamePromoView[0].dataset.areaName;
    const track = gamePromoView[0].dataset.track;
    const promoData = {
      track,
      'area-name': areaName
    };
    this.attachPromoData(promoData);
  }
  attachPromoData(promoData) {
    const infoPlayButtons = $('.js-casino-info-play, .js-casino-info-demoplay');
    Object.keys(promoData).forEach(key => {
      infoPlayButtons.attr("data-".concat(key), promoData[key]);
    });
  }
  destroy() {
    this.el.firstChild && this.el.firstChild.remove();
    this.childViews.forEach(child => child.destroy && child.destroy());
    this.navigateBackEl && svs.components.casino.shared.isPnp() && this.navigateBackEl.removeEventListener('click', this.navigateBackEvent);
  }
}
setGlobal('svs.components.casino.InfoPage', InfoPage);

 })(window);